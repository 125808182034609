import React, { useCallback } from "react";
import Axios from "axios";
import fileDownload from "js-file-download";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { Container, DropZone, SuccessWrapper } from "./styles";

function Upload({ renderLabel, value, index, onAnswerChange, onDisableActions }) {

  const downloadMedia = (media) => {
    const url = media.path;
    const extension = media.path.split('.').pop();
    const filename = media.name && media.name.endsWith(`.${extension}`)
      ? media.name
      : `${media.name || "arquivo"}.${extension}`;

    Axios.get(url, { responseType: "blob" }).then(res => {
      fileDownload(res.data, filename);
    });
  };

  return (
    <Container>
      <DropZone>
        {Array.isArray(value) ? (
          value.map((media, idx) => (
            media.path && (
              <SuccessWrapper key={idx}>
                <p onClick={() => downloadMedia(media)}>
                  <FaCloudDownloadAlt size={20} color="#1890ff" />
                  Fazer download do arquivo {media.name ? `(${media.name})` : ""}
                </p>
              </SuccessWrapper>
            )
          ))
        ) : (
          value && value.path && (
            <SuccessWrapper>
              <p onClick={() => downloadMedia(value)}>
                <FaCloudDownloadAlt size={20} color="#1890ff" />
                Fazer download do arquivo {value.name ? `(${value.name})` : ""}
              </p>
            </SuccessWrapper>
          )
        )}
      </DropZone>
    </Container>
  );
}

export default Upload;
