import styled from 'styled-components';
import { BLUE_LIT, GREEN_LIT } from '../../../utils/colors';

export const Container = styled.div`
    width: 100%;
    flex-direction: row;
    background: #fafafc;
    box-shadow: ${props => props.shadow ? '10px 0 15px rgba(0, 0, 0, .2)' : 'none'};
    padding: 10px;
    margin-bottom: 30px;
    justify-content: space-between;
    display: flex;
    align-items: center;
`

export const CourseTitle = styled.div`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
`

export const DescriptionUC = styled.div`
    background: #e7ebef;
    border-radius: 40px;
    padding: 3px 20px;
    margin: 5px 0 -24px 0;
    font-size: 12px;
    border: 1px solid #dbdbdb;
`

export const NavSection = styled.div`
    flex-direction: row;
    justify-content: ${props => props.isTrail ? "center" : "space-between"};
    display: flex;
    align-items: center;
    flex-basis: 75%;
    i{
        font-size: 30px;
    }
`

export const IconContainer = styled.div`
    display: flex;
    flex-basis: 20%;
`

export const NavButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0 20px;
    visibility: hidden;
`

export const MenuIcon = styled.button`
    background-color: ${props => props.backgroundColor ? `${props.backgroundColor}` : "#26ace1"};
    padding: 0 14px;
    border-radius: 100px;
    box-shadow: 5px 5px 10px rgba(38, 172, 255, .2);
    cursor: pointer;
    border: none;
    font-size: 25px;

    i{
        color: ${props => props.iconColor ? props.iconColor : 'white'};
    }
`

export const ProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 20%;
    visibility: ${props => props.isTrail ? "visible" : "hidden"}
`

export const ItemCardTitle = styled.span`
    color: ${BLUE_LIT}
`

export const CardItemCourse = styled.div`
    background: white;
    padding: 20px;
    box-shadow: rgba(0,0,0, 0.1) 5px 5px 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    min-height: 120px;
    flex: auto;
    margin-bottom: 10px;
    :nth-child(2) {
        margin-left: 10px;
    }
`

export const CardItemCourseContainer = styled.div`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
`

export const BoxTeacher = styled.div`
    flex-direction: row;
    align-items: center;
    display: flex;
    margin-top: 4px;
    margin-right: 3px;
`

export const ImageDefault = styled.div`
    background-color: gray
    border-radius: 100px;
    width: 75px;
    height: 75px;
    text-align: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    display: flex;
    margin-right: 10px;
`

export const TeacherName = styled.a`
    font-size: 20px;
`

export const TeacherThumbnail = styled.img`
    border: 5px solid ${GREEN_LIT}
`

export const LetterImageDefault = styled.a`
    font-size:35px;
    color: white;
`

export const PriceCourse = styled.h3`
    color: #27AAE1;
    font-size:30px;
`

export const PriceCourseOld = styled.span`
    color: #444;
    text-decoration: line-through;
`

export const LinkTeacher = styled.a`
    color: #27AAE1;
`

export const BlueLitText = styled.span`
    color: ${BLUE_LIT};
`

export const GreenLitText = styled.span`
    color: ${GREEN_LIT};
`

export const SuperiorContainer = styled.div`
    flex-direction: row;
    display: flex;
`

export const SuperiorItemDescription = styled.div`
    margin-left: 30px;
`

export const styles = {
    main: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    },
    navbar: {
      position: "fixed",
      top: 0,
      zIndex: 205,
      left: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      transition: "all .3s ease-out"
    },
    sidebar: {
      position: "fixed",
      zIndex: 1020,
      left: 0,
      top: 0,
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      width: "400px",
      transition: "all .3s ease-out",
      backgroundColor: '#eeeeee',
      borderRadius: 10,
      padding: "0 15px 20%"
    },
    content: {
      position: "relative",
      top: 52,
      right: 0,
      bottom: 0,
      overflowY: "auto",
      WebkitOverflowScrolling: "touch",
      transition: "all .3s ease-out"
    },
    loading: {
      position: "fixed",
      zIndex: 201,
      top: "66px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      filter: "blur(0px)",
      background: "transparent",
      height: "calc((100% - 66px) - 75px)"
    },
    erroCobranca: {
      background: "crimson",
      display: "flex",
      color: "white",
      fontWeight: "bold",
      justifyContent: "center",
      padding: "0.5em"
    }
  };