import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaSpinner, FaCheck, FaTrash } from "react-icons/fa";
import { AiOutlineUpload } from "react-icons/ai";
import _ from "lodash";

import { gateway } from "../../../../../../utils/api";
import { Container, DropZone, LoadingWrapper, FileList } from "./styles";

function Upload({
  index,
  onAnswerChange,
  onDisableActions,
  onUserComment,
  userComment
}) {
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]); // Lista dos arquivos enviados

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0 && uploadedFiles.length < 5) {
      try {
        onDisableActions(true);
        setLoading(true);
  
        const uploadPromises = acceptedFiles.map(async (file) => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("mediaType", "ASSESSMENT_RESPONSE");
  
          const response = await gateway.post("/media/media/upload", formData);
          const fileCode = _.get(response, "ox_standard.ox_identification.cr_code", null);
  
          return fileCode ? { name: file.name, code: fileCode } : null;
        });
  
        const newFiles = await Promise.all(uploadPromises);
        const validFiles = newFiles.filter(file => file !== null); // Remove arquivos inválidos
  
        const updatedFiles = [...uploadedFiles, ...validFiles].slice(0, 5); // Limita a 5 arquivos
        setUploadedFiles(updatedFiles);
        onAnswerChange(index, updatedFiles.map((file) => file.code)); // Passa array de códigos
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        onDisableActions(false);
      }
    } else {
      console.log("Limite de 5 arquivos atingido.");
    }
  }, [uploadedFiles, index, onAnswerChange, onDisableActions]);
  const removeFile = (fileCode) => {
    const updatedFiles = uploadedFiles.filter((file) => file.code !== fileCode);
    setUploadedFiles(updatedFiles);
    onAnswerChange(index, updatedFiles.map((file) => file.code)); // Atualiza com array atualizado
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: loading,
    multiple: true,
    maxFiles: 5 - uploadedFiles.length
  });

  return (
    <Container>
      <DropZone {...getRootProps()}>
        <input {...getInputProps()} />

        {loading ? (
          <LoadingWrapper>
            <FaSpinner size={20} color="#1890ff" />
            <span>Realizando upload dos seus arquivos ...</span>
          </LoadingWrapper>
        ) : (
          <p id="text-upload">
            Arraste e solte até 5 arquivos aqui ou clique para selecionar.
            <AiOutlineUpload />
          </p>
        )}
      </DropZone>

      {uploadedFiles.length > 0 && (
        <FileList>
          {uploadedFiles.map((file) => (
            <div key={file.code} className="file-item">
              <FaCheck size={20} color="#1890ff" />
              <span>{file.name}</span>
              <FaTrash onClick={() => removeFile(file.code)} />
            </div>
          ))}
        </FileList>
      )}

      <div className="form-group w-100 mt-4">
        <label>Adicione um comentário</label>
        <textarea
          className="form-control"
          rows="4"
          value={userComment}
          disabled={loading}
          onChange={(e) => onUserComment(e.target.value)}
        ></textarea>
      </div>
    </Container>
  );
}

export default Upload;
