import React, { useEffect, useState, useCallback, useMemo } from "react";
import _ from 'lodash';
import { gateway } from "../../utils/api";
import { openCallToAction } from '../../business/cta';

import CustomContentLoader from "./CustomContentLoader";
import LaneEventsComponent from "../Turma/pages/Home/components/LaneEvents";

// eslint-disable-next-line no-unused-vars
function LaneEvents({ data }) {
  const [classes, setClasses] = useState(null);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [errorEventsMessage] = useState('Erro ao buscar eventos');

  function loadEvents({ organizationCode: code }) {
    try {
      const url = `webbff/lit_app_web/event/class`;
      const body = {
        classCode: code
      };
      return gateway.post(url, body);
    } catch (error) {
      return [];
    } 
  }

  async function getEventsByTenant() {
    try {
      const url = `webbff/lit_app_web/event`;
      localStorage.removeItem('session');
      localStorage.removeItem('courseAccessed')
      return gateway.get(url);
    } catch (error) {
      return [];
    }
  }

  async function getEvenysByClass(studysGroup) { 
    let eventsResponse = await Promise.all(studysGroup.map(loadEvents));
    eventsResponse = eventsResponse.reduce((accumulator, currentValue) => {
      return [...accumulator, ...currentValue];
    }, []);
    return eventsResponse;
  }

  useEffect(() => {
    async function getEvents() {
      setLoading(true);
      const getPromises = [getEventsByTenant().catch(() => [])];
      
      if(classes && classes.length > 0) {
        getPromises.push(getEvenysByClass(classes).catch(() => []));
      }

      const promiseResponses = await Promise.all(getPromises);
      const [eventsResponseOne = [], eventsResponseTwo = []] = promiseResponses
      const eventsResponse = [...eventsResponseOne, ...eventsResponseTwo]
        .sort((a,b) => new Date(a.dateStart) - new Date(b.dateStart));

      setEvents(_.uniqBy(eventsResponse, 'crCode'));

      if(classes !== null) {
        setLoading(false);
      }
    }

    getEvents();
  }, [classes]);

  useEffect(() => {
    async function fetchClasses() {
      try {
        const response = await gateway("/webbff/lit_app_web/classes");
        setClasses(response);
      } catch (error) {
        console.log(error);
        setClasses([]);
      }
    }
    fetchClasses();
  }, []);

  const handleCallToAction = useCallback(cta => {
    openCallToAction(cta);
  }, []);

  const title = useMemo(() => {
    return _.get(data, 'lane.name', "Eventos");
  }, [data]);

  if(loading) {
    const array = new Array(1).fill();
      return (
        <>
          {array.map(() => {
            return (
              <CustomContentLoader
                tooltipText="Carregando eventos"
                title="Carregando eventos"
              />
            );
          })}
        </>
      );
  }

  if(!loading && events.length > 0) {
    return (
      <div className="cursos col-12" style={{ zIndex: 26 }}>
        <LaneEventsComponent
          loading={loading}
          title={title} 
          data={events}
          fullButton 
          errorEventsMessage={errorEventsMessage}
          onClick={(el) => {
            handleCallToAction({
              ...el.cta,
              courseCrCode: el.courseCodes[0] || ''
            })
          }} 
        />
      </div>
    )
  }
   
  return null;
  
}

export default LaneEvents;
