import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Img from 'react-image';
import _ from 'lodash';
import swal from '@sweetalert/with-react';
import Modal from 'react-responsive-modal';

import { Creators as NotificationsActions } from '../../store/ducks/notifications/actions';
import { Creators as AlertActions } from '../../store/ducks/alert/actions';
import { UserActions } from '../../store/ducks/user';

import api, { gateway } from '../../utils/api';

import FullLoading from '../shared/FullLoading';
import CartaoErro from '../CartaoErro';
import SmartBar from '../SmartBar';
import NavBarContext from '../../context/navBar/NavBarContext';
import GenericAlert from '../shared/GenericAlert';
import Notificacao from './Notificacao';
import ModalPolitica from '../Modals/ModalPolitica';
import ModalTenancy from './ModalTenancy';
import Video from '../ModalVideo/Video';
import history from '../../utils/history';

import hamburger from '../../images/hambuger.png';
import placeholder from '../../images/placeholder-avatar.png';
import * as Styles from './styles';
import { ModalRegisterPasswordMobile } from './ModalRegisterPasswordMobile';
import DarkMode from './DarkMode';

const LOGO_HOME_SAINTPAUL = require('../../images/logo-saintpaulwhite.png');

function NavBarSuperior({
  home,
  courses,
  trialData,
  setSmartBar,
  searchText,
  noPosition,
  erroPagamento,
  showSmartBar,
  reviewSmartBar,
  removeSmartBar,
  setOpenPlans,
  setMyClasses,
  countNotificationsNotReadRequest,
  qtdNotif,
  perfil,
  toggleModalRestrict,
  toggleModalRestrictLitPass
}) {
  const {
    showGenericAlert,
    showRecommendationPlanAlert,
    hasExpiredOrNearExpirationInvoice,
    tenancy,
    tenantName,
    showLinkBoletos,
    showMyInvoicesButton,
    openModalRegisterMobilePassword
  } = useContext(NavBarContext);

  const [loading, setLoading] = useState(false);
  const [headerGradient, setHeaderGradient] = useState(true);
  const [classes, setClasses] = useState([]);
  const [urlBiblioteca, setUrlBiblioteca] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [showNotifications, setShowNotifications] = useState({
    display: 'none'
  });
  const [showModalPolitica, setShowModalPolitica] = useState(false);
  const [urlVideo, setUrlVideo] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [headerWidth, setHeaderWidth] = useState('100%');
  const [scrolled] = useState(false);

  const settings = JSON.parse(localStorage.getItem('settings')) || null;
  const tenant = localStorage.getItem('tenant') || '';
  const company = localStorage.getItem('company') || ' ';
  const freeUser = JSON.parse(localStorage.getItem('freeUser'));
  const showPaul = JSON.parse(localStorage.getItem('showPaul')) || null;
  const social = JSON.parse(localStorage.getItem('socialUser'));
  const menuMaxWindow = window.window.innerHeight - 100;
  const urlImg = perfil.data.linkPhoto || placeholder;
  const { firstName, enableSocialName, socialName } = perfil.data;

  const { headerBackgroundColor, headerBackgroundGradient } = _.get(
    settings,
    `${tenant}`,
    {}
  );

  // TODO - adicionar depois
  const emailValidate = JSON.parse(localStorage.getItem('emailValidate'));
  const hasPasswordMobileInLocalStorage = localStorage.getItem(
    'hasPasswordMobile'
  );
  const showButtonToRegisterMobilePassword = hasPasswordMobileInLocalStorage
    ? !JSON.parse(hasPasswordMobileInLocalStorage)
    : false;
  const showMessageError = localStorage.getItem(
    '57e0854551b1dca003c67c384c37a346'
  );

  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const getHeight = () =>
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  const checkSizeChanged = () => {
    const currentWidth = getWidth();
    const currentUrl = window.location.pathname;
    const urlSplashed = currentUrl.split('/');
    const isNewCourseScreen = urlSplashed.find(item => item === 'curso-novo');
    const sizeWidth =
      currentWidth >= 1920 && !isNewCourseScreen ? 1920 : '100%';
    setHeaderWidth(sizeWidth);
  };

  const checkContainerDivHeight = () => {
    const height = getHeight();
    const divElement = document.getElementById('root');
    const divHeight = divElement.clientHeight;
    if (divHeight < height) {
      document.getElementById('root').style.minHeight = `${height}px`;
    }
  };

  const handleScroll = () => {
    const messageIe = document.getElementById('message-ie');

    if (document.documentElement.scrollTop > 100) {
      setHeaderGradient(true);
      if (messageIe) messageIe.style.display = 'none';
    }
    if (document.documentElement.scrollTop === 0) {
      if (home && !courses) setHeaderGradient(false);
      if (messageIe) messageIe.style.display = 'block';
    }
  };

  const leave = () => {
    const tenant = localStorage.getItem('tenant');
    const logoutRedirectUrl = _.get(
      settings,
      `${tenant}.logoutRedirectUrl`,
      false
    );
    localStorage.clear();
    sessionStorage.clear();
    removeSmartBar();
    if (logoutRedirectUrl && logoutRedirectUrl !== 'false') {
      window.location.href = logoutRedirectUrl;
    }
    history.push(`/${tenant !== 'LIT_BUSINESS' ? tenant.toLowerCase() : ''}`);
  };

  const verifyStatusAccount = async () => {
    const nowDate = new Date().getDate();
    const nowMonth = new Date().getMonth();
    const dateLogin = localStorage.getItem('dateLogin');
    const newDate = dateLogin ? dateLogin.split('/') : null;
    if (!newDate || newDate[0] < nowDate || newDate[1] > nowMonth) {
      leave();
    }
  };

  const search = e => {
    e.preventDefault();

    if (inputValue === '' || inputValue.trim() === '') {
      return;
    }

    history.push(`/busca?s=${inputValue}`);
    // history.push({ pathname: `/busca?s=${query}`, state: { query } });
    // if (typeof onSearch === 'function') {
    //   onSearch();
    // }
  };

  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  const openLibrary = async () => {
    const idProfile = localStorage.getItem('idProfile');
    const urlTutorial = `/getTutorialByUserIndex/${idProfile}/2/`;

    const libraryUser = JSON.parse(localStorage.getItem('libraryUser'));
    const naRealUser = JSON.parse(localStorage.getItem('naRealUser'));

    if (libraryUser && !naRealUser) {
      setLoading(true);

      try {
        const tutorial = await api.get(urlTutorial);
        const url = `webbff/lit_app_web/home/v2/library`;
        const response = await gateway.get(url);
        setUrlBiblioteca(response.url);
        if (tutorial.watched) {
          if (response) {
            setLoading(false);
            window.open(`${response.url}`, '_blank');
          }
        } else {
          setOpenModal(true);
          setUrlVideo(tutorial.url);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    } else {
      if (naRealUser) {
        toggleModalRestrict(true, 'book');
        return;
      }
      toggleModalRestrictLitPass(true, 'book');
    }
  };

  const fetchClasses = async () => {
    try {
      const response = await gateway('/webbff/lit_app_web/classes');

      const loginWithoutPassword = JSON.parse(
        localStorage.getItem('loginWithoutPassword')
      );
      const classes = response;
      setClasses(classes);
      setMyClasses(response);

      if (loginWithoutPassword) {
        setClasses(classes);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //  const handleOpenClassCallToAction = async classContent => {
  //    swal({
  //      title: `Redirecionamento para turma`,
  //      // text: `O item que você selecionou encontra-se em outro local, por isso iremos lhe encaminhar para outro site em uma nova aba. Clique no botão "prosseguir" para avançar`,
  //      buttons: {
  //        confirm: {
  //          text: 'Ir para a tela de turma',
  //          value: true,
  //          visible: true,
  //          className: 'btn-alert btn-alert-confirm-onboarding'
  //        },
  //        cancel: {
  //          text: 'Continuar na Home',
  //          value: false,
  //          visible: true,
  //          className: 'btn-alert'
  //        }
  //      }
  //    }).then(value => {
  //      if (value) {
  //        const selectedClass = classes.find(
  //          cl => cl.contentCode === classContent.contentCode
  //        );
  //        localStorage.setItem('homeCrCode', selectedClass.homeCrCode);
  //        history.push({
  //          pathname: `/turma/${classContent.contentCode}`,
  //          state: { turma: classContent }
  //        });
  //      } else {
  //        const selectedClass = classes.find(
  //          cl => cl.contentCode === classContent.contentCode
  //        );
  //
  //        localStorage.setItem('homeCrCode', selectedClass.homeCrCode);
  //        window.location.reload();
  //      }
  //    });
  //  };

  const handleOpenClassCallToAction = async classContent => {
    const selectedClass = classes.find(
      cl => cl.contentCode === classContent.contentCode
    );

    localStorage.setItem('homeCrCode', selectedClass.homeCrCode);

    history.push({
      pathname: `/turma/${classContent.contentCode}`,
      state: { turma: classContent }
    });
  };

  const handleSelectClass = async item => {
    localStorage.setItem('selectedClassName', item.name);
    localStorage.setItem('selectedClassCrCode', item.organizationCode);
    await handleOpenClassCallToAction(item);
  };

  const handleSelectDefaultHome = async () => {
    history.push(`/home`);
  };

  const loadData = async () => {
    await fetchClasses();
    if (!Object.keys(trialData).length) {
      const countDown = JSON.parse(localStorage.getItem('countDown'));
      if (countDown) {
        const data = {
          text: countDown.html,
          background: countDown.backgroundColor,
          timer: countDown.countDown,
          textbutton: countDown.buttonText
        };
        setSmartBar(data);
      }
    }
    verifyStatusAccount();
    if (home && !courses) {
      setHeaderGradient(false);
    }
  };

  const listenerScroll = () => {
    // Adicione um ouvinte de evento para detectar o rolamento
    window.addEventListener('scroll', handleScroll);

    // Não se esqueça de remover o ouvinte de evento ao desmontar o componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  };

  let topPosition = '';

  if (emailValidate === false && !noPosition) {
    topPosition = 'top-position';
  } else {
    topPosition = '';
  }

  const openBiblioteca = () => {
    setOpenModal(false);
    window.open(urlBiblioteca, '_blank');
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const hideNotificacoes = () => {
    setShowNotifications({
      display: 'none'
    });
  };

  const showNotificacoes = () => {
    setShowNotifications({
      display: 'flex'
    });
  };

  const countNotif = () => {
    countNotificationsNotReadRequest();
  };

  const showDiscountIndicating =
    _.get(settings, 'profile.showDiscountIndicating', 'true') === 'true';

  const showRecommendationsLink = showDiscountIndicating && !freeUser;

  const navClasses = useMemo(() => {
    let classes = 'navbar navbar-expand-lg navbar-light navbar-lit';
    if ((showSmartBar || showGenericAlert) && reviewSmartBar && !courses)
      classes += ' sticky-top';
    if (headerGradient) {
      classes += ' navbar-lit-gradient';
    } else if (scrolled) {
      classes += ' navbar-lit-gradient';
    } else {
      classes += ' navbar-lit-light';
    }
    return classes;
  }, [headerGradient, scrolled]);

  useEffect(() => {
    checkContainerDivHeight();
    loadData();
    listenerScroll();
    checkSizeChanged();

    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        checkSizeChanged();
      }, 150);
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    if (searchText !== inputValue) {
      setInputValue(searchText);
    }
  }, [searchText]);

  const logoIfDomainHasSaintPaul = useMemo(() => {
    // eslint-disable-next-line prefer-destructuring
    const hostname = window.location.hostname;
    if (
      hostname.toLowerCase() === 'app.saintpaul.com.br' &&
      tenant === 'LIT_BUSINESS'
    ) {
      return LOGO_HOME_SAINTPAUL;
    }

    const hasTenant = Object.prototype.hasOwnProperty.call(settings, tenant);
    return hasTenant ? _.get(settings[tenant], 'logoUrl', '') : '';
  }, [window.location]);

  const showReturnToDefaultHome = useMemo(() => {
    const hasHome = window.location.pathname === '/home';
    return hasHome;
  }, [window.location]);

  return (
    <>
      {loading && (
        <FullLoading>
          <p style={{ fontWeight: 'bold' }} className="no-margin">
            AGUARDE
          </p>
          <p className="no-margin">Carregando Biblioteca virtual </p>
          <p className="no-margin">Bons Estudos!</p>
          <p className="no-margin">Time LIT</p>
        </FullLoading>
      )}
      <>
        <div
          className={`${topPosition}`}
          style={
            courses
              ? { width: '100%', zIndex: '999' }
              : { width: '100%', position: 'fixed', zIndex: '1000' }
          }
        >
          {erroPagamento || (showMessageError === 'true' && <CartaoErro />)}

          {showSmartBar && reviewSmartBar && !courses && (
            <SmartBar
              text={trialData.text}
              background={trialData.background}
              onClose={removeSmartBar}
              redirectToPerfil={setOpenPlans}
              timer={trialData.timer}
              textbutton={trialData.textbutton}
            />
          )}

          {showGenericAlert && reviewSmartBar && !courses && (
            <GenericAlert color="#856404" bgColor="#fff3cd">
              Você possui um boleto vencido ou próximo a vencer, &nbsp;
              <Link to="/historico-de-pagamento">
                <b> clique para visualizar</b>
              </Link>
            </GenericAlert>
          )}

          {showRecommendationPlanAlert && home && (
            <GenericAlert color="#856404" bgColor="#fff3cd">
              Envie os convites para os dependentes do seu plano, &nbsp;
              <Link to="/perfilAluno?open=recommendationPlan">
                <b> clique aqui para fazer isso!</b>
              </Link>
            </GenericAlert>
          )}

          {hasExpiredOrNearExpirationInvoice && reviewSmartBar && !courses && (
            <GenericAlert color="#856404" bgColor="#fff3cd">
              Você possui um boleto vencido ou próximo a vencer, &nbsp;
              <Link to="/invoicePaymentView">
                <b> clique para visualizar</b>
              </Link>
              <sup>new</sup>
            </GenericAlert>
          )}

          <nav
            className={navClasses}
            style={{
              background: headerGradient ? headerBackgroundColor : '',
              width: headerWidth
            }}
          >
            <Styles.TenantImageLink
              className="nav-link"
              style={{ minWidth: 150, padding: '0rem 1rem' }}
            >
              <a href="/home">
                {settings && settings[tenant] && settings[tenant].logoUrl ? (
                  <img
                    id="tenant-logo"
                    alt={tenantName}
                    src={logoIfDomainHasSaintPaul}
                    style={{ height: 55 }}
                  />
                ) : (
                  tenantName
                )}
              </a>
            </Styles.TenantImageLink>

            <button
              className="navbar-toggler toggler-lit"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span
                className="navbar-toggler-icon"
                style={{
                  backgroundImage: `url(${hamburger})`,
                  filter: 'brightness(0) invert(1)'
                }}
              />
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <div style={{ flex: '1' }}>
                <form
                  className="form-inline my-2 my-lg-0 form-large"
                  onSubmit={search}
                >
                  <div className="input-group col-lg-12">
                    <input
                      className="navbar-lit-search form-control border-right-0 search "
                      type="text"
                      value={inputValue}
                      placeholder="Busque por cursos, trilhas, professores, grupos, outros onlearners etc."
                      aria-label="Search"
                      onChange={handleInputChange}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        onClick={search}
                        className="btn border-left-0"
                        data-tip="Buscar"
                        style={{
                          borderRadius: '0 10px 10px 0',
                          color: 'rgba(255, 255, 255, 0.8)',
                          background: 'rgba(0, 0, 0, 0.3)',
                          border: '1px solid rgba(255,255,255, 0.25)'
                        }}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <ul
                className="navbar-nav"
                id="navbarNavDropdown"
                style={{ flex: '1', justifyContent: 'flex-end' }}
              >
                {company.toUpperCase() === 'ANGLO' && (
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://maestro.plurall.net/#/bookshelf"
                      target="_blank"
                    >
                      Apostilas
                    </a>
                  </li>
                )}
                {process.env.REACT_APP_MY_ENV === 'development' && (
                  <li className="nav-item">
                    <DarkMode />
                  </li>
                )}
                {classes && classes.length > 0 && (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      id="navbarDropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                    >
                      <span data-tip="Acesse seus cursos, tilhas e micro-momentos">
                        {localStorage.getItem('selectedClassName')
                          ? localStorage.getItem('selectedClassName')
                          : 'Turmas'}
                      </span>
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      {classes.map((item, index) => (
                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                        <a
                          key={index}
                          onClick={() => handleSelectClass(item)}
                          className="dropdown-item"
                          data-tip="Minhas turmas"
                        >
                          {item.name}
                        </a>
                      ))}
                      {!showReturnToDefaultHome && (
                        <a
                          onClick={() => handleSelectDefaultHome()}
                          className="dropdown-item"
                          role="button"
                          tabIndex="0"
                          aria-label="Voltar para home principal"
                        >
                          Voltar para home principal
                        </a>
                      )}
                    </div>
                  </li>
                )}
                {console.log(window.location)}
                <li
                  className="nav-item dropdown"
                  style={{ alignSelf: 'center', margin: '0 5px' }}
                >
                  <div
                    role="button"
                    onClick={() => showNotificacoes()}
                    className="buttonNotif"
                  >
                    <i className="fa fa-bell" aria-hidden="true" />
                    {qtdNotif > 0 && (
                      <div className="button__badge">{qtdNotif}</div>
                    )}
                    <Notificacao
                      hide={() => hideNotificacoes()}
                      showNotes={showNotifications}
                      countNotif={() => countNotif()}
                    />
                  </div>
                </li>
                <Styles.DropDownMenuContainer
                  menuMaxWindow={menuMaxWindow}
                  className="nav-item dropdown"
                >
                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>
                      <Img
                        className="rounded-circle"
                        style={{ width: 38, height: 38, marginTop: '-5px' }}
                        src={urlImg}
                      />
                    </span>
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <Link
                      to={
                        company.toLocaleUpperCase() !== 'ANGLO' &&
                        '/perfilAluno'
                      }
                      className="dropdown-item"
                    >
                      <Styles.HoverUserName>
                        Olá, {enableSocialName ? socialName : firstName}!
                      </Styles.HoverUserName>
                      <small>Área de Estudo: {tenantName}</small>
                    </Link>

                    {showButtonToRegisterMobilePassword && (
                      <Styles.ButtonModal
                        onClick={() => openModalRegisterMobilePassword()}
                        type="button"
                        className="dropdown-item"
                      >
                        Cadastrar senha para o aplicativo
                      </Styles.ButtonModal>
                    )}

                    <Styles.MenuSectionTitle>
                      Dados da conta
                    </Styles.MenuSectionTitle>
                    {company.toUpperCase() !== 'ANGLO' && (
                      <Link
                        to="/perfilAluno"
                        className="dropdown-item"
                        data-tip="Conte-nos sobre você e defina suas preferências de privacidade"
                      >
                        Perfil
                      </Link>
                    )}

                    {showLinkBoletos && (
                      <Link
                        to="/historico-de-pagamento"
                        className="dropdown-item"
                        data-tip="Visualizar boletos"
                      >
                        Meus boletos
                      </Link>
                    )}

                    {/* {showMyInvoicesButton && (
                      <Link
                        to="/invoice-payment-history"
                        className="dropdown-item"
                        data-tip="Visualizar boletos"
                      >
                        Meus boletos <sup>new</sup>
                      </Link>
                    )} */}

                    {showRecommendationsLink && (
                      <Link
                        to="/recommendations"
                        className="dropdown-item"
                        data-tip=""
                      >
                        Indique amigos
                      </Link>
                    )}

                    <a
                      role="button"
                      data-tip="Conheça nossa Politica e Privacidade"
                      className="dropdown-item"
                      onClick={() => setShowModalPolitica(true)}
                    >
                      Termos e Privacidade
                    </a>
                    {showPaul && (
                      <Link
                        to={{
                          pathname: '/chatPaul',
                          state: { fromNavBar: true }
                        }}
                        className="dropdown-item"
                      >
                        Sobre o LIT
                      </Link>
                    )}

                    {
                      <Link
                        to="/ajuda"
                        className="dropdown-item"
                        data-tip="Neste canal você pode tirar dúvidas, fazer elogios, sugerir melhorias, reportar erros e conhecer melhor a plataforma"
                      >
                        Fale conosco
                      </Link>
                    }

                    <Styles.MenuSectionTitle>Conteúdo</Styles.MenuSectionTitle>

                    <Link
                      to="/cursos"
                      className="dropdown-item"
                      data-tip="Cursos e trilhas que está cursando no momento"
                    >
                      Meus cursos
                    </Link>

                    {company.toUpperCase() !== 'ANGLO' && (
                      <a
                        className="dropdown-item"
                        role="button"
                        onClick={() => openLibrary()}
                        data-tip="Acesse mais de 8 mil títulos de temas diversos"
                      >
                        Ir para biblioteca
                      </a>
                    )}

                    <Styles.MenuSectionTitle>
                      Área de Estudo
                    </Styles.MenuSectionTitle>

                    <ModalTenancy
                      tenancy={tenancy}
                      settings={settings}
                      tenant={tenant}
                      tenantName={tenantName}
                    />

                    <Styles.Divider></Styles.Divider>
                    <a
                      onClick={() => leave()}
                      role="button"
                      className="dropdown-item"
                      data-tip="Log out da plataforma"
                    >
                      Sair
                    </a>
                  </div>
                </Styles.DropDownMenuContainer>
              </ul>
            </div>
            <Modal
              open={openModal}
              onClose={() => closeModal()}
              classNames={{ modal: 'lit-perfil-form' }}
              closeOnOverlayClick={false}
            >
              <div className="modal-content">
                <div className="modal-header justify-content-center">
                  <h5 style={{ color: '#27aae1' }} className="modal-title">
                    Biblioteca
                  </h5>
                </div>
                <div className="modal-body">
                  <Video indexSubtitle={2} url={urlVideo} />
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      onClick={() => openBiblioteca()}
                      className="btn btn-nivelamento btn-FontWeight6"
                      style={{ marginTop: '1rem' }}
                    >
                      <a>Ir para biblioteca</a>
                    </button>
                  </div>
                </div>
              </div>
            </Modal>

            <ModalRegisterPasswordMobile />

            <ModalPolitica
              visible={showModalPolitica}
              onClose={() => {
                setShowModalPolitica(false);
              }}
            />
          </nav>
        </div>
      </>
    </>
  );
}

const mapStateToProps = store => {
  return {
    perfil: store.user.profile,
    erroPagamento: store.user.profile.erroPagamento,
    allowViewVoucher: store.voucher.allowViewVoucher,
    qtdNotif: store.notifications.qtdNotRead,
    showAlert: store.alert.show,
    type: store.alert.type,
    trialData: store.alert.trialData,
    showSmartBar: store.alert.showSmartBar
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...NotificationsActions,
      ...AlertActions,
      ...UserActions.ProfileActions,
      ...UserActions.StudyGroupActions
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavBarSuperior)
);
