import React, { useEffect, useState, useMemo } from "react"
import { useDispatch } from "react-redux";
import _ from 'lodash';
import { toast } from "react-toastify"
import NavBarSuperior from "../../NavBarSuperior/NavBarSuperior"
import ProgressBar from "../../LitAtomicComponents/basicComponents/ProgressBar";
import * as Styles from './styles';
import { LitButton } from "../../shared/LitButton";
import { gateway } from "../../../utils/api";
import Accordion from "./subcomponents/Accordion";
import history from "../../../utils/history";
import { UserActions } from "../../../store/ducks/user";
import CourseRequirements from "./subcomponents/CourseRequirements";
import spinner from '../../../images/paul_loading.gif';
import { getInitialsTeacher } from "../../../utils/functions";

function ApresentacaoCurso(props) {

  const [courseData, setCourseData] = useState({})
  const [coursePreRequirements, setCoursePreRequirements] = useState({})
  const [plans, setPlans] = useState([])
  const [showMorePlans, setShowMorePlans] = useState(false)
  const [loading, setLoading] = useState(true)

  const tenant = localStorage.getItem('tenant') || ''
  const settings = JSON.parse(localStorage.getItem('settings')) || null

  const { headerBackgroundColor } = _.get(
    settings,
    `${tenant}`,
    {}
  );

  const displatch = useDispatch();

  const _checkCourseRedirect = response => {

    const { alreadyCourseCrCode } = props;
    if(alreadyCourseCrCode){
      setCourseData(response)
      setLoading(false);
      return;
    }

    try {
      const {
        match: {
          params: { courseCrCode }
        },
        location: { search },
      } = props;
      const { courseNotStarted } = response;
      const params = new URLSearchParams(search);
      const from = params.get('from');
  
      if(courseData && !courseNotStarted && from !== 'about') {
        history.push(`/curso-novo/${courseCrCode}`)
      } else {
        setCourseData(response)
        setLoading(false);
      }
    } catch (error) {
      
    }
  }

  const notify = (text, type = "success") => {
    toast(text, {
      type,
      closeButton: false,
    })
  }

  const getCourseData = async courseCrCode => {
    try {
      const response = await gateway.get(`/content/content/${courseCrCode}/course-details`)      
      _checkCourseRedirect(response)
    } catch (error) {
      console.error(error)
      notify(error.message, 'error');
      history.push('/home')
    }
  }
  
  const getPreRequirements = async courseCrCode => {
    try {
      const response = await gateway.get(`/content/content/${courseCrCode}/pre-and-post-requirements`)      
      setCoursePreRequirements(response)
    } catch (error) {
      console.error(error)
    }
  }

  const getPlansByCourse = async courseCrCode => {
    try {
     const response = await gateway.get(`/offer/offer/${courseCrCode}/offer-course`);
     if(response.length > 0){
      setPlans(response)
     } else {
      setPlans([])
      setShowMorePlans(false)
     }          
    } catch (error) {
      console.error(error)
      setPlans([])
      setShowMorePlans(false)
    }
  }

  const goToCourse = () => {
    const {
      alreadyCourseCrCode = null,
      alreadyLockedCourse = false
    } = props;
    if(alreadyCourseCrCode){
      if(!alreadyLockedCourse){
        history.push(`/curso-novo/${alreadyCourseCrCode}`)      
      } else {
        displatch(UserActions.ProfileActions.toggleModalRestrict(true))
      }
    } else {
      const {
        match: {
          params: { courseCrCode, lockedCourse }
        },
      } = props;
      if(lockedCourse === 'false'){
        history.push(`/curso-novo/${courseCrCode}`)      
      } else {
        displatch(UserActions.ProfileActions.toggleModalRestrict(true))
      }
    }
  }

  const progressMessage = useMemo(() => {
    const { lockedCourse = false } = courseData;
    return lockedCourse ? 'Você não tem acesso a essa informação por enquanto.' : 'Em breve';
  },[courseData]);

  const getAllCourseData = async courseCrCode => {
    await Promise.all([
      getCourseData(courseCrCode),
      getPlansByCourse(courseCrCode),
      getPreRequirements(courseCrCode)
    ])
  }

  useEffect(() => {

    const {
      alreadyCourseCrCode = null
    } = props;
    if(alreadyCourseCrCode !== null){
      getAllCourseData(alreadyCourseCrCode)
    }

    try {
      const {
        match: {
          params: { courseCrCode }
        },
        alreadyCourseCrCode = null
      } = props;
      const courseCode = alreadyCourseCrCode || courseCrCode
      getAllCourseData(courseCode)
    } catch (error) {
      //
    }
  }, []);

  const planPrice = useMemo(() => {

    let cheapestPlan = null;
    
    for (const offer of plans) {
        if (offer.price > 0 && (cheapestPlan === null || offer.price < cheapestPlan.price)) {
            cheapestPlan = offer;
        }
    }
    
    return cheapestPlan;

  }, [plans]);

  const otherPlans = useMemo(() => {
    const othersPlans = plans.filter(item => item.price > 0);
    return othersPlans;
  }, [plans]);

  const showPlans = useMemo(() => {
    const {
      alreadyLockedCourse = false
    } = props;

    if(alreadyLockedCourse){
      return alreadyLockedCourse
    }

    try {
      const {
        match: {
          params: { lockedCourse = null }
        }
      } = props;
      return alreadyLockedCourse || (planPrice && lockedCourse === 'true')
    } catch (error) {
      return false
    }
    
  }, [plans, planPrice]);

  const showHideDetails = useMemo(() => {
    const {
      resumeView = false
    } = props;
    return !resumeView;
  }, [props]);

  const workloadEffort = useMemo(() => {
    let effort = 0;
    const { courseWorkload = 0 } = courseData;
    if(courseWorkload <= 2){
      effort = '1 dia';
    } else if(courseWorkload > 2 && courseWorkload <= 5){
      effort = Math.round(courseWorkload/2)
      effort += ' dias';
    } else if(courseWorkload > 5){
      effort = Math.round(courseWorkload/5)
      effort += ' semanas';
    }
    return effort;
  }, [courseData]);

  return (<>
    {showHideDetails && <NavBarSuperior courses reviewSmartBar />}
    <Styles.Container className="course-presentation" shadow>
        <Styles.IconContainer>
          <Styles.MenuIcon onClick={() => window.history.back()} sidebarOpened={false}>
            <i className="fa fa-angle-left" />
          </Styles.MenuIcon> 
        </Styles.IconContainer>
        <Styles.NavSection isTrail={false}>
              <Styles.NavButton 
                onClick={() => console.log('prev')}>
                <i className="fa fa-angle-left" />
            </Styles.NavButton>
            <Styles.CourseTitle>
                <strong>{loading ? 'Carregando ...' : courseData.name}</strong>
                <Styles.DescriptionUC className="title-description">
                  Visão geral
                </Styles.DescriptionUC>
            </Styles.CourseTitle>
            <Styles.NavButton 
                onClick={() => console.log('next')}>
                <i className="fa fa-angle-right" />
            </Styles.NavButton>
        </Styles.NavSection>
        <Styles.ProgressContainer isTrail={false}>
            <ProgressBar data={65} />
        </Styles.ProgressContainer>
      </Styles.Container>   
      <div className="container-fluid">
      {loading ? <div className="text-center">
        <img
          alt="loading"
          style={{
            maxHeight: '80%',
            animation: 'loading-spin infinite 10s linear'
          }}
          src={spinner}
        />
      </div> : <div className="px-4 pb-5 pb-5">
        <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-8 col-12">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12">
                  <LitButton onClick={goToCourse} style={{ marginRight: 10, padding: '15px 20px', boxShadow: '5px 3px 20px rgba(36,170,225,.4)', minWidth: 350 }}>
                    <i className="fa fa-play" style={{ fontSize: 25 }}></i> Iniciar/Retomar
                  </LitButton>
                </div>
              </div>

              <ul className="nav nav-tabs nav-fill mt-5 custom-tabs-lit" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    onClick={() => {}}
                    className="nav-link active"
                    id="sobre-tab"
                    data-toggle="tab"
                    href="#sobre"
                    role="tab"
                    aria-controls="sobre"
                    aria-selected="true"
                  >
                    <span data-tip={`Um resumo do curso - ${courseData.name}`}>
                      SOBRE O CURSO
                    </span>
                  </a>
                </li>
                <li className="nav-item" style={{ visibility: 'hidden' }}>
                  <a
                    onClick={() => {}}
                    className="nav-link"
                    id="historico-tab"
                    data-toggle="tab"
                    href="#historico"
                    role="tab"
                    aria-controls="historico"
                    aria-selected="false"
                  >
                    <div id="myTab-HistoryDiv" data-tip="Verifique o seu desempenho">
                      MEU PROGRESSO
                    </div>
                  </a>
                </li>
              </ul>

              <div className="tab-content pt-5 pb-5 text-color-gray" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="sobre"
                  role="tabpanel"
                  aria-labelledby="sobre-tab"
                >
                  <h3 className="mb-4">APRESENTAÇÃO</h3>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">     
                      <div dangerouslySetInnerHTML={{ __html: courseData.description }} /> 
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <Styles.CardItemCourseContainer>
                        <Styles.CardItemCourse margin>
                          <i className="fa fa-clock-o" style={{ color: '#4ab0e4', fontSize: 18 }}></i>
                          <Styles.ItemCardTitle>Carga horária</Styles.ItemCardTitle>
                          <strong>{courseData.courseWorkload}h</strong>
                        </Styles.CardItemCourse>
                        {courseData.levelCourse !== 'Nível do curso não informado' && (
                          <Styles.CardItemCourse>
                            <i className="fa fa-bar-chart" style={{ color: '#4ab0e4', fontSize: 18 }}></i>
                            <Styles.ItemCardTitle>Nível</Styles.ItemCardTitle>
                            <strong>{courseData.levelCourse}</strong>
                          </Styles.CardItemCourse>
                        )}
                      </Styles.CardItemCourseContainer>
                      <Styles.CardItemCourseContainer>
                        <Styles.CardItemCourse margin>
                          <i className="fa fa-list" style={{ color: '#4ab0e4', fontSize: 18 }}></i>
                          <Styles.ItemCardTitle>Pílulas de aprendizagem</Styles.ItemCardTitle>
                          <strong>{courseData.pills}</strong>
                        </Styles.CardItemCourse>
                        {courseData.totalNumberOfStudentsOnTheCourse >= 100 && (
                          <Styles.CardItemCourse>
                            <i className="fa fa-users" style={{ color: '#4ab0e4', fontSize: 18 }}></i>
                            <strong>{courseData.totalNumberOfStudentsOnTheCourse} já iniciaram</strong>
                          </Styles.CardItemCourse>
                        )}
                      </Styles.CardItemCourseContainer>
                      <Styles.CardItemCourseContainer>
                        <Styles.CardItemCourse margin>
                          <i className="fa fa-check-square-o" style={{ color: '#4ab0e4', fontSize: 18 }}></i>
                          <Styles.ItemCardTitle>Possui avaliação final</Styles.ItemCardTitle>
                          <strong>{courseData.hasFinalExam ? 'Sim' : 'Não'}</strong>
                        </Styles.CardItemCourse>
                        <Styles.CardItemCourse>
                          <i className="fa fa-graduation-cap" style={{ color: '#4ab0e4', fontSize: 18 }}></i>
                          <Styles.ItemCardTitle>Certificado</Styles.ItemCardTitle>
                          <strong>{courseData.courseCertificationIsProgress ? 'Concluir 90% dos objetos' 
                        : `Nota >= ${!_.isNaN(Number(courseData.courseApprovalMinGrade)/10) 
                        ? Number(courseData.courseApprovalMinGrade)/10 : 0}`}</strong>
                        </Styles.CardItemCourse>
                      </Styles.CardItemCourseContainer>
                      <Styles.CardItemCourseContainer>
                        <Styles.CardItemCourse margin>
                          <i className="fa fa-clock-o" style={{ color: '#4ab0e4', fontSize: 18 }}></i>
                          <Styles.ItemCardTitle>Dedicação Estimada</Styles.ItemCardTitle>
                          <strong>{workloadEffort}</strong>
                        </Styles.CardItemCourse>
                      </Styles.CardItemCourseContainer>
                    </div>
                  </div>

                  {showHideDetails && <CourseRequirements requirements={coursePreRequirements} currentCourse={courseData} />}
                
                  {courseData.professors && <div className="mt-4">
                    <h4 className="mb-2">{courseData.professors.length >= 2 ? 'PROFESSORES' : 'PROFESSOR'}</h4>
                    {courseData.professors.map(item =><Styles.BoxTeacher>
                        {item.image ? <Styles.TeacherThumbnail src={item.image} width={75} height={75} alt="" className="img-rounded mr-2" style={{ borderRadius: 100 }} /> 
                        : <Styles.ImageDefault style={{ background: headerBackgroundColor }}>
                          <Styles.LetterImageDefault href={`/professor/${item.code}`}>{getInitialsTeacher(item)}</Styles.LetterImageDefault>
                          </Styles.ImageDefault>
                          }
                        <div>
                          <Styles.TeacherName href={`/professor/${item.code}`}>{item.name}</Styles.TeacherName><br />
                          <Styles.LinkTeacher href={`/professor/${item.code}`}>Ver perfil do professor</Styles.LinkTeacher>
                        </div>
                      </Styles.BoxTeacher> )}
                    </div>
                    } 
                </div>
                <div className="tab-pane fade" id="historico" role="tabpanel" aria-labelledby="historico-tab">
                  {progressMessage}
                </div>
                </div>
              </div>

            
            <div className="col-lg-4 col-md-4 col-sm-4 col-12 text-center">

            {showPlans && (<fieldset className="fieldset-lit border rounded-3 pl-3 pr-3">
                <legend className="float-none w-auto px-3" style={{ color: '#27AAE1', fontSize: 16 }}>Quanto custa</legend>

                De <Styles.PriceCourseOld>R${planPrice.price.toFixed(2)}</Styles.PriceCourseOld> por
                <Styles.PriceCourse>{planPrice.priceBy}</Styles.PriceCourse>

                <LitButton onClick={() => {
                  displatch(UserActions.ProfileActions.toggleModalRestrict(true))
                }} style={{ padding: '5px 10px', margin: '10px 0', boxShadow: '5px 3px 20px rgba(36,170,225,.4)' }}>
                  Comprar curso
                </LitButton>

                {!showMorePlans ? <p><small><a href="javascript:void(0)" onClick={() => setShowMorePlans(true)}>Ver outros planos</a></small></p>
                  : <p><small><a href="javascript:void(0)" onClick={() => setShowMorePlans(false)}>Fechar</a></small></p>}

                {showMorePlans && otherPlans && otherPlans.length > 0 && (
                  <ul className="list-group mt-0">
                    {otherPlans.map(item => (
                      <li className="list-group-item">
                          <span>{item.name} - <strong>R${item.price},00</strong></span>
                      </li>
                    ))}
                </ul>
                )}
            </fieldset>)}

              {courseData && courseData.cover && (
                <img src={courseData.cover} alt="imagem de apresentacao do curso" className="d-inline-block mt-2" height={200} />
              )}

              <Accordion modules={courseData.modules} showHideDetails={showHideDetails} />
            </div>
          </div>
      </div>}
      </div> 
        
      </>
  )
}

export default ApresentacaoCurso 
