import React, { useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import PrivateRoute from '../Components/Hoc/PrivateRoute';
import ValidCpfRoutes from '../Components/Hoc/ValidCpfRoutes';
// Components
import Anglo from '../Components/Anglo';
import LoginNovo from '../Components/LoginNovo/Login';

import CadastroPerfil from '../Components/CadastroPerfil';
import Carreira from '../Components/Carreira/Carreira';
import AllCourses from '../Components/Home/AllCourses';
import Home from '../Components/Home/Home';
import FormPagamento from '../Components/Pagamento/Formulario';
import PreCadastro from '../Components/PreCadastro';

import Onboarding from '../Components/Onboarding';

import Certificados from '../Components/Certificados';

import Ajuda from '../Components/Atendimento/Ajuda';
import Busca from '../Components/Busca/Busca';
import Analisando from '../Components/Carreira/Analisando';
import Endereco from '../Components/Carreira/Endereco';
import ExperienciaProfissional from '../Components/Carreira/ExperienciaProfissional';
import Formacao from '../Components/Carreira/Formacao';
import InformacoesGerais from '../Components/Carreira/InformacoesGerais';
import Interesses from '../Components/Carreira/Interesses';
import PaulDicas from '../Components/Carreira/PaulDicas';
import PersonalizarTexto from '../Components/Carreira/PersonalizarTexto';
import ResultadosWatson from '../Components/Carreira/ResultadosWatson';
import ConfirmarEmail from '../Components/ConfirmarEmail';
import ConsultaCPF from '../Components/ConsultaCPF';
import ConsultarTermo from '../Components/ConsultarTermo';
import CursoNovo from '../Components/CursoNovo';
import Cursos from '../Components/Cursos';
import EsqueciSenha from '../Components/EsqueciSenha/EsqueciSenha';
import AtualizarSenha from '../Components/AtualizarSenha';
import Eventos from '../Components/Eventos';
import Faturamento from '../Components/Faturamento';
import VerifyIE from '../Components/Hoc/onlyVerifyIE';
import RequireAuth from '../Components/Hoc/requireAuth';
import InvoicePaymentHistory from '../Components/InvoicePaymentHistory';
import Logout from '../Components/Logout';
import Maintenance from '../Components/Maintenance';
import NewPayment from '../Components/NewPayment';
import Notificacoes from '../Components/Notificacoes';
import PerfilAluno from '../Components/PerfilAluno/PerfilAluno';
import PerfilProfessor from '../Components/PerfilProfessor';
import PerfilPublico from '../Components/PerfilPublico/PerfilPublico';
import Recommendations from '../Components/Recommendations';
import RedirectAds from '../Components/RedirectAds';
import Grupo from '../Components/Social/Grupo';
import Timeline from '../Components/Social/Timeline';
import CondicoesUso from '../Components/Termos/CondicoesUso';
import Privacidade from '../Components/Termos/Privacidade';
import TesteNivelamento from '../Components/TesteNivelamento/TesteNivelamento';
import Turma from '../Components/Turma';
import Validador from '../Components/Validador';
import WatsonChatPage from '../Components/WatsonChat/WatsonChatPage';
import WhatNow from '../Components/WhatNow';
import ApresentacaoCurso from '../Components/CursoNovo/Apresentacao';
import PaymentHistory from '../Components/PaymentHistory';
import InvoicePaymentView from '../Components/Pagamento/componentes/InvoicePaymentView';
import Assessment from '../Components/Assessment';
import AssessmentForum from '../Components/AssessmentForum';
import AssessmentView from '../Components/AssessmentView';
import AssessmentWaitingRoom from '../Components/AssessmentWaitingRoom';
import Avaliacao from '../Components/Avaliacao';
import NewFeed from '../Components/NewFeed/Feed';
import AdaptablePost from '../Components/NewSocial/AdaptablePost';
import GroupPage from '../Components/NewSocial/Group';
import SearchGroup from '../Components/NewSocial/SearchGroup';
import SocialProfile from '../Components/NewSocial/SocialProfile';
import WebexMeeting from '../Components/Webex/Meeting';
import WebexOAuth from '../Components/Webex/OAuth';
import WebexSamlSSO from '../Components/Webex/SamlSSO';
import WebexSamlSSOTest from '../Components/Webex/SamlSSOTest';
import ThankYou from '../Components/ThankYou';
import history from '../utils/history';
import LtiLaunch from '../Components/Lti';
import FacebookTracking from '../Components/FacebookTracking';
import Teacher from '../Components/CursoNovo/Teacher';
import { CheckAddressOnboarding } from '../Components/CheckAddressOnboarding';
import Library from '../Components/Library';
import MobileVideoVimeo from '../Components/CursoNovo/MobileVideoVimeo';

const Routes = () => (
  <Router history={history}>
    <>
      <CheckAddressOnboarding />
      <FacebookTracking />
      <Switch>
        <Route path="/lti" component={LtiLaunch} />
        <Route path="/anglo" component={Anglo} />
        <Route path="/oauth-plurall" component={LoginNovo} />
        <Route path="/oauth-webex" component={WebexOAuth} />

        <Route exact path="/linkedin" component={LinkedInCallback} />

        <Route exact path="/" component={VerifyIE(LoginNovo)} />

        <Route exact path="/beta/feed" component={VerifyIE(NewFeed)} />

        <Route
          exact
          path="/beta/social/socialgroup/:id"
          component={VerifyIE(GroupPage)}
        />
        <Route
          exact
          path="/beta/social/externalgroup/:id"
          component={VerifyIE(SocialProfile)}
        />
        <Route
          exact
          path="/beta/social/internalgroup"
          component={VerifyIE(SocialProfile)}
        />
        <Route
          exact
          path="/beta/social/searchgroup"
          component={VerifyIE(SearchGroup)}
        />
        <Route
          exact
          path="/beta/social/adaptablePost"
          component={VerifyIE(AdaptablePost)}
        />

        <Route exact path="/litpass" component={VerifyIE(LoginNovo)} />
        <Route exact path="/vocacao" component={VerifyIE(LoginNovo)} />
        <Route path="/redirect" component={RedirectAds} />
        <PrivateRoute path="/ajuda" component={RequireAuth(Ajuda)} />
        <Route path="/validador" component={VerifyIE(Validador)} />
        <Route path="/consultarTermo" component={VerifyIE(ConsultarTermo)} />
        <Route path="/cadastroPerfil" component={VerifyIE(CadastroPerfil)} />
        <Route
          path="/cadastroPerfilLitPass"
          component={VerifyIE(CadastroPerfil)}
        />
        <Route path="/payment" component={NewPayment} />
        <PrivateRoute
          path="/invoice-payment-history"
          component={InvoicePaymentHistory}
        />

        <Route
          path="/cadastroPerfilVocacao"
          component={VerifyIE(CadastroPerfil)}
        />
        <Route path="/precadastro" component={PreCadastro} />
        <Route path="/esqueciSenha" component={VerifyIE(EsqueciSenha)} />
        <Route path="/atualizar-senha" component={VerifyIE(AtualizarSenha)} />
        <Route path="/cadastrar-senha" component={VerifyIE(AtualizarSenha)} />
        <Route path="/confirmarEmail" component={VerifyIE(ConfirmarEmail)} />
        <Route path="/consultaCpf" component={VerifyIE(ConsultaCPF)} />
        <ValidCpfRoutes path="/planos" component={VerifyIE(NewPayment)} />
        <ValidCpfRoutes path="/pagamento" component={VerifyIE(FormPagamento)} />
        <Route path="/logout" component={VerifyIE(Logout)} />
        <Route path="/manutencao" component={VerifyIE(Maintenance)} />
        <Route
          path="/vitrine/curso/:idCurso"
          render={props => (
            <Redirect to={`/curso/${props.match.params.idCurso}`} />
          )}
        />
        <PrivateRoute
          path="/historico-de-pagamento"
          component={RequireAuth(PaymentHistory)}
        />
        <PrivateRoute path="/home" component={RequireAuth(Home)} />
        <PrivateRoute path="/cursos" component={RequireAuth(Cursos)} />
        <PrivateRoute
          path="/certificados"
          component={RequireAuth(Certificados)}
        />
        <PrivateRoute
          path="/todos-cursos"
          component={RequireAuth(AllCourses)}
        />
        <PrivateRoute path="/curso-novo/:idCurso" component={CursoNovo} />
        <PrivateRoute
          path="/apresentacao-do-curso/:courseCrCode/:lockedCourse"
          component={RequireAuth(ApresentacaoCurso)}
        />
        <PrivateRoute
          path="/professor/:teacherCrCode"
          component={RequireAuth(Teacher)}
        />
        <PrivateRoute path="/carreira" component={RequireAuth(Carreira)} />
        <PrivateRoute path="/onboarding" component={RequireAuth(Onboarding)} />
        <PrivateRoute path="/avaliacao" component={RequireAuth(Avaliacao)} />
        <PrivateRoute
          path="/informacoesGerais"
          component={RequireAuth(InformacoesGerais)}
        />
        <PrivateRoute path="/interesses" component={RequireAuth(Interesses)} />
        <PrivateRoute path="/formacao" component={RequireAuth(Formacao)} />
        <PrivateRoute
          path="/notificacoes"
          component={RequireAuth(Notificacoes)}
        />
        <PrivateRoute
          path="/exProfissional"
          component={RequireAuth(ExperienciaProfissional)}
        />
        <PrivateRoute path="/endereco" component={RequireAuth(Endereco)} />
        <PrivateRoute path="/paulDicas" component={RequireAuth(PaulDicas)} />
        <PrivateRoute
          path="/personalizar"
          component={RequireAuth(PersonalizarTexto)}
        />
        <PrivateRoute path="/analisando" component={RequireAuth(Analisando)} />
        <PrivateRoute
          path="/resultados"
          component={RequireAuth(ResultadosWatson)}
        />
        <PrivateRoute
          path="/chatPaul"
          component={RequireAuth(WatsonChatPage)}
        />
        <PrivateRoute
          path="/testeNivelamento"
          component={RequireAuth(TesteNivelamento)}
        />
        <PrivateRoute
          path="/perfilAluno"
          component={RequireAuth(PerfilAluno)}
        />
        <PrivateRoute
          exact
          path="/perfilProfessor/:id"
          component={RequireAuth(PerfilProfessor)}
        />
        <PrivateRoute
          path="/perfilPublico/:idProfile"
          component={RequireAuth(PerfilPublico)}
        />
        <PrivateRoute path="/timeline" component={RequireAuth(Timeline)} />
        <PrivateRoute
          exact
          path="/grupo/:idGrupo"
          component={RequireAuth(Grupo)}
        />
        <PrivateRoute path="/busca" component={RequireAuth(Busca)} />
        <PrivateRoute path="/oque" component={RequireAuth(WhatNow)} />
        <PrivateRoute
          path="/faturamento"
          component={RequireAuth(Faturamento)}
        />
        <PrivateRoute
          path="/invoicePaymentView"
          component={RequireAuth(InvoicePaymentView)}
        />

        <Route path="/webex/sso" component={RequireAuth(WebexSamlSSO)} />
        <PrivateRoute
          path="/webex/ssotest"
          component={RequireAuth(WebexSamlSSOTest)}
        />
        <PrivateRoute
          path="/webex/meeting"
          component={RequireAuth(WebexMeeting)}
        />

        <PrivateRoute path="/turma/:code" component={RequireAuth(Turma)} />
        <PrivateRoute
          path="/recommendations"
          component={RequireAuth(Recommendations)}
        />
        <PrivateRoute path="/eventos" component={RequireAuth(Eventos)} />

        <PrivateRoute
          path="/library"
          component={Library}
        />

        <Route path="/termos-condicoes-uso" component={CondicoesUso} />
        <Route path="/assessment" component={Assessment} />
        <Route
          path="/assessment-waiting-room"
          component={AssessmentWaitingRoom}
        />
        <Route path="/assessment-view" component={AssessmentView} />
        <Route path="/assessment-forum" component={AssessmentForum} />
        <Route path="/politica-privacidade" component={Privacidade} />
        <Route path="/sucesso_:plan" component={ThankYou} />
        <Route path="/mobile-video-vimeo" component={MobileVideoVimeo} />
        <Route path="*" exact component={VerifyIE(LoginNovo)} />
      </Switch>
    </>
  </Router>
);

export default Routes;
