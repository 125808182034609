/* eslint-disable react/forbid-prop-types */
import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import _ from 'lodash';
import { format } from "date-fns";
import pt from "date-fns/locale/pt";
import {BsChevronRight} from "react-icons/bs"

import * as Styles from './styles';

function Screen({event, onClick}) {

  const color = _.get(event, 'color.hex', '27aae1');
  const fontColor = _.get(event, 'fontColor.hex', 'fff');
  const cover = _.get(event, 'cover.url');
  const available = _.get(event, 'available', true); 
  const endsOnDifferentDays = _.get(event, 'endsOnDifferentDays', true);

  const dateStart = useMemo(() => {
    const eventDate = _.get(event, 'dateStart');
    return format(new Date(eventDate), "dd/MM/yyyy '-' EEEE", {
      locale: pt
    });
  }, [event])

  const dateEnd = useMemo(() => {
    const eventDate = _.get(event, 'dateEnd');
    return format(new Date(eventDate), "dd/MM/yyyy '-' EEEE", {
      locale: pt
    });
  }, [event])

  function getFirstThreeLettersAfterCharacter(string, caractere) {
    const index = string.indexOf(caractere);
    if (index !== -1 && index + 1 < string.length) {
      const letters = string.substr(index + 2, 3).toUpperCase();
      const data = string.substring(0, index);
      return `${letters  } - ${  data}`;
    }
    return "";
  }

  function limitString(string, limite) {
    if (string.length <= limite) {
      return string;
    } 
      return `${string.substring(0, limite)  }...`;
    
  }
 
  return (
    <Styles.Container 
      available={available}
      cover={cover}  
      background={color || '27aae1'} 
      highlights={event.cta} 
      onClick={() => available && onClick(event)}
    >

      <Styles.WrapperBoxDate
        fontColor={fontColor} 
        cover={cover} 
        background={color || '27aae1'}
      >
         {!cover && <Styles.BoxDate cover={cover}>
         <Styles.CardDate cover={cover}>
            <Styles.ComplementEventName cover={cover} fontColor={fontColor} status={event.eventStatus}>{limitString(event.name, 118)}</Styles.ComplementEventName>
          </Styles.CardDate>
        </Styles.BoxDate>}
      </Styles.WrapperBoxDate>
     
      <Styles.Content>
        <Styles.ContainerPeriod cover={cover}>
          <Styles.ComplementDay cover={cover} status={event.eventStatus} style={{marginRight: '2px'}}>
            {getFirstThreeLettersAfterCharacter(dateStart, "-")} 
          </Styles.ComplementDay>

          {endsOnDifferentDays &&   
          <>
           <BsChevronRight size="14" color='#6E6E6E'/>
            <Styles.ComplementDay cover={cover} status={event.eventStatus} style={{marginLeft: '2px'}}>
                {getFirstThreeLettersAfterCharacter(dateEnd, "-")}
            </Styles.ComplementDay>
          </>
          }
        </Styles.ContainerPeriod>
        <Styles.Hours>
          {event.duration}
        </Styles.Hours>
      </Styles.Content>

    </Styles.Container>
  );
}

Screen.propTypes = {
  event: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

Screen.defaultProps = {
  onClick: () => {}
}

export default Screen;
