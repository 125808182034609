import React, { memo, useState, useEffect } from "react";
import styled from 'styled-components';
import CourseLane from "./CourseLane";
import EventLane from "./EventLane";
import Loading from "../../../Loading/Loading";
import { gateway } from "../../../../utils/api";


const MessageWrapper = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #555;
`;
const Lanes = ({ turma, selectPage }) => {
  const [loading, setLoading] = useState(true);
  const [coursesData, setResponseCoursesData] = useState({ docs: [] });
  const [events, setEvents] = useState([]);

  async function loadEvents(code) {
    const url = `webbff/lit_app_web/event/class`;
    const body = { classCode: code };
    const response = await gateway.post(url, body);
    return response || [];
  }

  async function loadCourses(code) {
    const url = `webbff/lit_app_web/classes/${code}/enrollment/content`;
    const response = await gateway.get(url);
    return response || { docs: [] };
  }

  useEffect(() => {
    if (turma) {
      setLoading(true);

      Promise.all([
        loadEvents(turma.organizationCode),
        loadCourses(turma.contentCode),
      ])
        .then(([eventsResponse, coursesResponse]) => {
          setEvents(eventsResponse);
          setResponseCoursesData(coursesResponse);
        })
        .catch((error) => {
          console.error("Erro ao carregar dados:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [turma]);

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
        <div style={{ transform: "scale(0.5)" }}>
          <Loading />
        </div>
      </div>
    );
  }

  const noEvents = events.length === 0;
  const noCourses = !coursesData.docs || coursesData.docs.length === 0;

  return (
    <>
      {noEvents && noCourses && <MessageWrapper>Nenhum dado disponível.</MessageWrapper>}
      {events.length > 0 && (
        <EventLane events={events} selectPage={selectPage} />
      )}
      {!noCourses && (
        <CourseLane coursesData={coursesData.docs} />
      )}
      {/* <TrailsLane /> */}
    </>
  );
};

export default memo(Lanes);
