import React from 'react';
import * as Styles from './styles';

function Screen({currentParticipants, prevPage, currentPage, nextPage, indexOfLastItem, itemsPerPage, participants}) {
  return (
    <Styles.Container>
      <Styles.ParticipantsGrid>
        {console.log(currentParticipants, 'participant')}
        {currentParticipants.map((participant) => (
          <Styles.ParticipantCard key={participant.id}>
            <Styles.ProfileImage src={participant.profileImage || 'https://i.imgur.com/NJ4Wb7s.png'} alt={participant.name} />
            <h4 style={{fontSize: '20px', color: '#274061', fontWeight: 700}}>{participant.name}</h4>
            <p style={{fontSize: '14px', color: '#344B55', fontWeight: 400}}>{participant.email || ''}</p>
          </Styles.ParticipantCard>
        ))}
      </Styles.ParticipantsGrid>
      {participants.length >= itemsPerPage && (
            <Styles.Pagination>
                <Styles.PaginationButton onClick={prevPage} disabled={currentPage === 1}>
                Anterior
                </Styles.PaginationButton>
                <Styles.PaginationButton onClick={nextPage} disabled={indexOfLastItem >= participants.length}>
                Próxima
                </Styles.PaginationButton>
            </Styles.Pagination>
      )}
    </Styles.Container>
  );
}


export default Screen;
