import React, { useState, useMemo, useCallback,useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import history from '../../utils/history';
import { gateway } from "../../utils/api";


import Screen from './screen';

export default function Turma({ location }) {

  const [activePage, setActivePage] = useState('HOME');
  const [students, setStudents] = useState([])

  const turma = useMemo(() => {
    return _.get(location, 'state.turma', null);
  }, [location]);

  async function loadStudentByClass(code) {
    try {
      const url = `webbff/lit_app_web/classes/fetchStudentsByClass?classId=${code}`;
      const response = await gateway.get(url);
      setStudents(response);
    } catch (error) {
      console.log(error);
    } 
}

  const handleSelectPage = useCallback((page) => {
    setActivePage(page);
  }, []);

  useEffect(() => {
    if(turma) loadStudentByClass(turma.contentCode)
  }, [turma])
  

  if (!turma) {
    return (
      <Redirect to="/home" />
    )
  }
  return (
    turma && students && (
      <Screen
        turma={turma}
        students={students}
        selectPage={handleSelectPage}
        activePage={activePage}
      />
    )
  )
}
