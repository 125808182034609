import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import api from '../../../utils/api';
import { toastDefaultOptions } from '../../../utils/functions';
import * as Styles from './styles';

class SaibaMais extends Component {
  notify = text => {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 8500
    });
  };

  handleAnexo = obj => {
    const media = _.get(obj, 'media', '');
    switch (media && media.type) {
      case 'PDF':
      case 'VIDEO':
      case 'EXE':
      case 'TXT':
      case 'XLS':
      case 'ZIP':
        this.downloadAnexo(media);
        break;
      // case "BIB":
      //   this.startBook(obj.idAttachment)
      //   break

      default:
        console.log(media && media.type, 'download handler not found');
        break;
    }
  };

  startBook = (path = '') => {
    const { handle } = this.state;
    if (handle === 2) {
      if (path !== '') {
        this.selectBook(path);
      }
    } else {
      const idProfile = localStorage.getItem('idProfile');
      api
        .get(`library/oauth/${idProfile}`)
        .then(response => {
          if (response.status === 200) {
            console.log('Autenticou livro', response);
            this.setState({
              authlivro: response.message,
              handle: handle + 1
            });
            if (path !== '') {
              this.selectBook(path);
            }
          }
        })
        .catch(error => console.log(error));
    }
  };

  selectBook = path => {
    window.open(`${path}`);
  };

  downloadAnexo = ({ path }) => {
    if (path) {
      const linkDownload = document.createElement('a');
      linkDownload.setAttribute('download', '');
      linkDownload.setAttribute('href', path);
      linkDownload.setAttribute('target', '_blank');
      linkDownload.click();
    }
  };

  render() {
    const { selected, course } = this.props;
    let knowMore = course.knowMore || [];

    if (course.type === 'TRAIL') {
      const { courseCode } = selected;
      const { children = [] } = course;
      const selectedChild = children.find(child => child.code === courseCode);

      if (selectedChild && Array.isArray(selectedChild.knowMore)) {
        const { knowMore: knowMoreChild } = selectedChild;
        if (Array.isArray(knowMoreChild)) {
          knowMore = knowMoreChild;
        }
      }
    }

    const hasKnowMoreItems = Array.isArray(knowMore) && knowMore.length > 0;
    const hasSelectedKnowMoreItems =
      selected.knowMore &&
      Array.isArray(selected.knowMore) &&
      selected.knowMore.length > 0;

    return (
      <div className="col-lg-8">
        <Styles.Container>
          <h4>SAIBA MAIS</h4>

          {!hasKnowMoreItems && !hasSelectedKnowMoreItems && (
            <p style={{ fontSize: '14px', textAlign: 'center' }}>
              Nenhum item para ser exibido.
            </p>
          )}

          {hasKnowMoreItems &&
            knowMore.map(lib => (
              <Styles.ItemContainer key={lib.id}>
                <Styles.ContainerIcon>
                  {lib.type === 'BOOK'
                    ? lib.type
                    : _.get(lib, 'media.type', '?')}
                </Styles.ContainerIcon>
                <Styles.ItemDescription>
                  {_.get(lib, 'name', '?')}
                </Styles.ItemDescription>
                {_.get(lib, 'media', false) && (
                  <Styles.BtnDownload
                    type="button"
                    className="btn btn-light"
                    onClick={() => this.handleAnexo(lib)}
                  >
                    <i className="fa fa-download" aria-hidden="true" />
                  </Styles.BtnDownload>
                )}
              </Styles.ItemContainer>
            ))}

          {hasSelectedKnowMoreItems ? (
            <>
              <hr
                style={{
                  width: '100%',
                  margin: '10px auto',
                  background: '#f3f2f0'
                }}
              />
              <p style={{ fontSize: '16px', textAlign: 'center' }}>
                Saiba mais: {selected.name}
              </p>
              {selected.knowMore.map(lib => (
                <Styles.Objeto key={lib.id}>
                  <div className="anexo-saiba-mais-curso">
                    <div className="anexo-texto-saiba-mais-curso">
                      {_.get(lib, 'media.type', '?')}
                    </div>
                  </div>
                  <span style={{ flex: 2, fontSize: 14 }}>
                    {_.get(lib, 'media.name', '?')}
                  </span>
                  {_.get(lib, 'media', false) && (
                    <button
                      type="button"
                      className="btn btn-light align-self-end"
                      onClick={() => this.handleAnexo(lib)}
                    >
                      <i className="fa fa-download" aria-hidden="true" />
                    </button>
                  )}
                </Styles.Objeto>
              ))}
            </>
          ) : null}
        </Styles.Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selected: state.newCourse.selected,
  course: state.newCourse.data
});

export default connect(mapStateToProps)(SaibaMais);
