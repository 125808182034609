import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import EventFilter from "../_ui/EventFilter";
import { openCallToAction } from "../../../../../business/cta";

const LaneContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  position: relative;
  min-height: 300px; 
`;


const LaneHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  width: 100%;
`;

const LaneTitle = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
`;

const LaneCount = styled.span`
  font-size: 20px;
  color: #D3D3D3;
  margin-left: 8px;
  margin-bottom: 8px;
`;

const EventCard = styled.div`
  min-width: 240px;
  max-width: 300px;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
  transition: transform 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1; 
  padding-bottom: 10px; 

  &:hover {
    transform: scale(1.05);
  }
`;

const EventImage = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
`;

const EventContent = styled.div`
  margin-top: 5px;
  padding: 15px;
  padding-bottom: 10px; 
  flex-grow: 1; 
`;
const EventDate = styled.div`
  font-size: 12px;
  color: #999;
  margin-top: 5px;
`;

const EventTitle = styled.h3`
  font-size: 18px;
  color: #333;
  margin: 0;
`;

const LoadingMessage = styled.div`
  font-size: 18px;
  color: #666;
  text-align: center;
  padding: 20px;
`;

const responsive = {
  veryDesktop: {
    breakpoint: { max: 3000, min: 1560 },
    items: 5,
    slidesToSlide: 2,
  },
  largDesktop: {
    breakpoint: { max: 1560, min: 1385 },
    items: 4,
    slidesToSlide: 2,
  },
  desktop: {
    breakpoint: { max: 1385, min: 1024 },
    items: 3,
    slidesToSlide: 2, 
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const ArrowButton = styled.button`
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #333;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => (props.left ? "left: 10px;" : "right: 10px;")}

  &:hover {
    background-color: #e0e0e0;
  }
`;

const CustomLeftArrow = ({ onClick }) => (
  <ArrowButton left onClick={onClick}>
    &#10094;
  </ArrowButton>
);

const CustomRightArrow = ({ onClick }) => (
  <ArrowButton onClick={onClick}>
    &#10095;
  </ArrowButton>
);

const EventLane = ({ events = [], selectPage }) => {
  const [loading, setLoading] = useState(true);

  const formatDate = (dataISO) => {
    const data = new Date(dataISO);
    const diaSemana = new Intl.DateTimeFormat("pt-BR", { weekday: "short" }).format(data);
    const dataFormatada = new Intl.DateTimeFormat("pt-BR").format(data);
    return `${diaSemana.toUpperCase()} - ${dataFormatada}`;
  };

  const handleCallToAction = (el) => {
    openCallToAction({
      ...el.cta,
      courseCrCode: el.courseCodes[0] || '',
    });
  };

  useEffect(() => {
    if (events.length > 0) {
      setLoading(false);
    }
  }, [events]);

  return (
    <LaneContainer>
      <LaneHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <LaneTitle>Eventos</LaneTitle>
          <LaneCount>({events.length})</LaneCount>
        </div>
        <div>
          <EventFilter selectPage={selectPage}/>
        </div>
      </LaneHeader>
      {loading ? (
        <LoadingMessage>Carregando eventos...</LoadingMessage>
      ) : (
        <Carousel
          responsive={responsive}
          swipeable
          draggable
          showDots={false}
          infinite={false}
          keyBoardControl
          customTransition="all 0.5s"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          itemClass="carousel-item-padding-20-px"
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}

        >
          {events.map((item, index) => (
              <>
                          <EventCard key={index} onClick={() => handleCallToAction(item)} style={{cursor: 'pointer'}}>
              <EventImage
                src={
                  item.cover && item.cover.url
                    ? item.cover.url
                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwT6h9NB_rWkYCPk0XfMmvkF6c-w9K4qZkJLcCwDe9G_BWLDl24_ibZU2U3Wi4xEPY9tY&usqp=CAU"
                }
                alt="Event"
              />
              <EventContent>
                <EventTitle>{item.name}</EventTitle>
                <EventDate>
                  {item.dateStart ? formatDate(item.dateStart) : ""} {" > "}{" "}
                  {item.dateEnd ? formatDate(item.dateEnd) : ""}
                </EventDate>
              </EventContent>
            </EventCard>
              <p style={{marginBottom: '2px'}}></p>
              </>
          ))}
                

        </Carousel>
      )}
    </LaneContainer>
  );
};

export default EventLane;
