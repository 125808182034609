import React, { useState } from "react";
import styled from "styled-components";

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
`;

const Label = styled.span`
  font-size: 14px;
  color: #333;
`;

const SelectWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Select = styled.select`
  appearance: none;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #666;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  padding-right: 30px;
`;

const DropdownArrow = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #fff;
  font-size: 10px;

`;

const ViewAllButton = styled.button`
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #333;
  border: none;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #000;
  }
`;

const EventFilter = ({selectPage}) => {
  const [filter, setFilter] = useState("future");

  return (
    <FilterContainer>
      <Label>Mostrando:</Label>
      <SelectWrapper>
        <Select value={filter} onChange={(e) => setFilter(e.target.value)}>
          <option value="future">Eventos futuros</option>
        </Select>
        <DropdownArrow>▼</DropdownArrow>
      </SelectWrapper>
      <ViewAllButton onClick={() => selectPage(`CALENDAR`)}>Ver todos</ViewAllButton>
    </FilterContainer>
  );
};

export default EventFilter;
