import React, {  useEffect, useState } from 'react';
import "./Script.css";
import VideoV2 from '../Media/VideoV2';

function MobileVideoVimeo({ location }) {

  const [selected, setSelected] = useState(null);
  const [videoHeight, setVideoHeight] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const idProfile = params.get('idProfile');
    const token = params.get('token');
    const tenant = params.get('tenant');
    const vdHeight = params.get('videoHeight');

    const selectedFrom = {
      code: params.get('code'),
      courseCode: params.get('courseCode'),
      hierarchy: params.get('hierarchy'),
      idDiscipline: params.get('hierarchy'),
      contentProgress: {
        ox_status: {
          nx_value: params.get('nx_value'),
          wk_status: params.get('wk_status'),
        }
      },
      knowMore: [],
      // knowMore: params.get('knowMore'),
      vimeo_url: params.get('vimeo_url')
    }

    localStorage.setItem('idProfile', idProfile);
    localStorage.setItem('token', token);
    localStorage.setItem('@Lit/token', token);
    localStorage.setItem('tenant', tenant);
    setSelected(selectedFrom);

    if(vdHeight){
      setVideoHeight(vdHeight)
    }
  }, []);

  if(!selected){
    return (
      <div>Processando ...</div>
    )
  }

  return (
    <VideoV2
      videoUrl={selected.vimeo_url}
      description={null}
      selected={selected}
      videoWidth="100%"
      videoHeight={videoHeight}
      fromMobile
    />
  )
}

export default MobileVideoVimeo;
