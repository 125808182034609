import React, { useEffect, useState, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { gateway } from '../../utils/api';
import { openCallToAction } from '../../business/cta';

import CustomContentLoader from './CustomContentLoader';
import LaneWarningsComponent from '../Turma/pages/Home/components/LaneWarnings';
import ModalWarningView from '../Modals/ModalWarningView';

// eslint-disable-next-line no-unused-vars
function LaneWarnings({ data }) {
  const [classes, setClasses] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasWarning, setHasWarning] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [errorEventsMessage] = useState('Erro ao buscar avisos');
  const [organizationCode, setOrganizationCode] = useState(
    localStorage.getItem('classCode')
  );

  // control modal
  const [element, setElement] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const onOpenModal = () => setOpenModal(true);
  const onCloseModal = () => setOpenModal(false);

  async function getWarningsByTenant() {
    try {
      setLoading(true);
      const url = `webbff/lit_app_web/warning`;
      const response = await gateway.get(url);
      if (_.get(response, 'docs', []).length > 0) {
        // setWarnings(response);
        setHasWarning(true);
      } else {
        // setWarnings([]);
        setHasWarning(false);
      }
      return _.get(response, 'docs', []);
    } catch (error) {
      return [];
    } finally {
      setLoading(false);
    }
  }

  async function loadWarnings(code) {
    try {
      const url = `webbff/lit_app_web/classes/${code}/warnings`;
      const response = await gateway.get(url);
      return _.get(response, 'docs', []);
    } catch (error) {
      console.log(error);
      // setErrorWarningsMessage("Erro ao buscar avisos");
    }
  }

  async function getWarningsByClass(studysGroup) {
    let eventsResponse = await Promise.all(
      studysGroup.map(item => loadWarnings(item.organizationCode))
    );
    eventsResponse = eventsResponse.flat();
    return eventsResponse;
  }

  useEffect(() => {
    async function getWarnings() {
      setLoading(true);
      const getPromises = [getWarningsByTenant().catch(() => [])];

      if (classes && classes.length > 0) {
        getPromises.push(getWarningsByClass(classes).catch(() => []));
      }
      const promiseResponses = await Promise.all(getPromises);
      const [eventsResponseOne = [], eventsResponseTwo = []] = promiseResponses;
      const eventsResponse = [...eventsResponseOne, ...eventsResponseTwo].sort(
        (a, b) => new Date(a.dateStart) - new Date(b.dateStart)
      );

      const arr = _.uniqBy(eventsResponse, 'code');
      setWarnings({ docs: arr });

      if (classes !== null) {
        setLoading(false);
      }
    }
    getWarnings();
  }, [classes]);

  useEffect(() => {
    async function fetchClasses() {
      try {
        const response = await gateway('/webbff/lit_app_web/classes');
        setClasses(response);
      } catch (error) {
        console.log(error);
        setClasses([]);
      }
    }
    fetchClasses();
  }, []);

  const openModalWarning = el => {
    setElement(el);
    onOpenModal();
  };

  const handleCallToAction = useCallback(cta => {
    const keys = Object.keys(cta);
    const values = Object.values(cta);
    const obj = {
      key: keys[0],
      value: values[0]
    };
    onCloseModal();
    openCallToAction(obj);
  }, []);

  const title = useMemo(() => {
    return _.get(data, 'lane.name', 'Avisos gerais da turma');
  }, [data]);

  if (loading) {
    const array = new Array(1).fill();
    return (
      <>
        {array.map(() => {
          return (
            <CustomContentLoader
              tooltipText="Carregando avisos"
              title="Carregando avisos"
            />
          );
        })}
      </>
    );
  }

  if (!loading && hasWarning) {
    return (
      <div className="cursos col-12" style={{ zIndex: 26 }}>
        <LaneWarningsComponent
          loading={loading}
          title={title}
          data={warnings}
          organizationCode={organizationCode}
          fullButton
          errorEventsMessage={errorEventsMessage}
          onClick={el => openModalWarning(el)}
        />
        <ModalWarningView
          openModal={openModal}
          onCloseModal={onCloseModal}
          onClick={handleCallToAction}
          element={element}
          onOrganization={false}
        />
      </div>
    );
  }

  return null;
}

export default LaneWarnings;
