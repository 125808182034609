import React, { useState } from 'react';
import Screen from './screen';


const ITEMS_PER_PAGE = 21;
const ParticipantsList = ({students}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentParticipants = students.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => setCurrentPage((prev) => prev + 1);
  const prevPage = () => setCurrentPage((prev) => prev - 1);
  return (
    <Screen 
        currentParticipants={currentParticipants} 
        prevPage={prevPage} 
        currentPage={currentPage} 
        nextPage={nextPage} 
        indexOfLastItem={indexOfLastItem}
        itemsPerPage={ITEMS_PER_PAGE}
        participants={students}
    />
  );
};

export default ParticipantsList;
