/* eslint-disable class-methods-use-this */
import React, { useState, useEffect } from 'react';

import 'react-alice-carousel/lib/alice-carousel.css';
import styled from 'styled-components';
import _, { lastIndexOf } from 'lodash';
import NewCourses from './Carousel/NewCourses';
import Courses from './Carousel/Courses';

import LitOnlearning from '../../images/lit.png';
import LitPassLogo from '../../images/litpass-logo.png';
import { gateway } from '../../utils/api';

import imgVocaco from '../../images/logo-vocacao.png';
import { getLoginTenantURL } from '../../utils/functions';

const BoxPromo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat;

  background-size: ${props => (props.noContain ? 'cover' : 'contain')};
  background-position: center;
`;

function LeftSideBar({ litpass, promo, isVocacao, origin }) {
  const [vitrineData, setVitrineData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [promoLeftBackground, setPromoLeftBackground] = useState({});
  const [loginSettingsState, setLoginSettingsState] = useState({});
  const [isContain, setIsContain] = useState(false);
  const [bgDefaultHeight, setBgDefaultHeight] = useState(0);
  const [renderBackgroundTenant, setRenderBackgroundTenant] = useState(false);
  const [showCoursesTenant, setShowCoursesTenant] = useState(false);
  const [tenantsValids] = useState([
    'LIT',
    'ANGLO',
    'LIT_SCHOOL',
    'LIT_HOMOLOG',
    'LIT_SAMPLES',
    'HINODE',
    'IBEF',
    'SAINTPAUL',
    'LIT_BUSINESS',
    'OBJETIVO_ABC',
    'MICHAEL_PAGE',
    'EXEMPLO_2',
    'VOCACAO',
    'LIT_NEGOCIOS',
    'FARIAS_BRITO',
    'VERACEL',
    'BANCO_BRASILIA',
    'BIOLAB',
    'BOM_JESUS',
    'CERRADINHO',
    'FAIRFAX',
    'PATRIA',
    'TTG',
    'CAIXA_ASSET',
    'B2C_EXTENSAO',
    'INTERPLAYERS',
    'ARTESANAL_INVESTIMENTOS',
    'RNP',
    'ITAU_UNIBANCO'
  ]);

  async function getImageByOrigin(origin) {
    try {
      const response = await gateway.get(
        `webbff/lit_app_web/content/${origin}`
      );
      if (response) {
        setRenderBackgroundTenant(true);
        setPromoLeftBackground({
          backgroundImage: _.get(response, 'url', ''),
          backgroundColor: _.get(response, 'backgroundColor', '')
        });
      } else {
        setPromoLeftBackground(null);
        setRenderBackgroundTenant(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function getVitrineLogin(tenant) {
    try {
      const response = await gateway.get(
        `webbff/lit_app_web/content/getVitrineLogin/${tenant}`
      );

      if (response) {
        setVitrineData(response);
        setShowCoursesTenant(true);
      } else {
        setPromoLeftBackground(null);
        setRenderBackgroundTenant(false);
      }
    } catch (error) {
      setPromoLeftBackground(null);
      setRenderBackgroundTenant(false);
    } finally {
      setLoading(false);
    }
  }

  function getPromoBackground() {
    const params = new URLSearchParams(window.location.search);
    const currentLoginTenant =
      getLoginTenantURL(window.location.href) || 'LIT_BUSINESS';
    const origin = params.get('o');
    if (origin) {
      getImageByOrigin(origin);
    }
    if (currentLoginTenant && !origin) {
      getVitrineLogin(currentLoginTenant);
    }
  }

  async function getLoginSettings() {
    let domain = '';
    let isValidTenant = false;
    const splittedURL = window.location.href.split('/');
    const lastItemIndexOfURL = lastIndexOf(splittedURL) - 1;
    if (splittedURL[lastItemIndexOfURL].includes('?')) {
      const [domainName] = splittedURL[lastItemIndexOfURL].split('?');
      domain = domainName;
    } else {
      domain = splittedURL[lastItemIndexOfURL];
    }

    if (tenantsValids.includes(domain.toUpperCase())) {
      isValidTenant = true;
      localStorage.setItem('loginTenant', domain.toUpperCase());
    }

    if (isValidTenant) {
      try {
        const domainSettings = await gateway.get(
          `/webbff/lit_app_web/settings/domainSetting/${domain}`
        );
        localStorage.setItem('domainSettings', JSON.stringify(domainSettings));
        setLoginSettingsState(domainSettings);
      } catch (err) {
        console.log('err', err.message);
      }
    } else {
      localStorage.setItem('loginTenant', '');
    }
  }

  useEffect(() => {
    getPromoBackground();
    getLoginSettings();
  }, []);

  const getHeight = () =>
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const checkSizeChanged = () => {
    const currentWidth = getWidth();
    const currentHeight = getHeight();
    const sizeContain = currentWidth > 1920;
    setIsContain(sizeContain);
    setBgDefaultHeight(currentHeight);
  };

  useEffect(() => {
    checkSizeChanged();
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => {
        checkSizeChanged();
      }, 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  if (loading) {
    return <div style={{ width: '55%', height: 100 }}></div>;
  }

  return (
    <>
      {!origin && !promo && !renderBackgroundTenant && !showCoursesTenant && (
        <div
          className={
            loginSettingsState.primaryBackgroundColor
              ? 'col-md-7 flex-column justify-content-center align-items-center d-none d-md-flex big d-sm-flex'
              : 'col-md-7 flex-column justify-content-center align-items-center d-none d-md-flex bg-loginLeft big d-sm-flex'
          }
          style={
            loginSettingsState.primaryBackgroundColor && {
              backgroundColor: loginSettingsState.primaryBackgroundColor
            }
          }
        >
          {!loginSettingsState.logoUrl && litpass && (
            <img
              src={LitPassLogo}
              width={200}
              // className="align-self-start"
              alt="lit-logo"
            />
          )}
          {!loginSettingsState.logoUrl && isVocacao && (
            <img
              src={LitOnlearning}
              width={200}
              // className="align-self-start"
              alt="lit-logo"
            />
          )}
          {!litpass && !isVocacao && !loginSettingsState.logoUrl && (
            <img
              src={LitOnlearning}
              height={100}
              // className="align-self-start ml-4 my-5"
              alt="lit-logo"
            />
          )}
          {loginSettingsState.logoUrl &&
          loginSettingsState.domain !== 'hinode' ? (
            <img
              src={loginSettingsState.logoUrl}
              height={100}
              // className="align-self-start ml-4 my-5"
              alt="lit-logo"
            />
          ) : null}
        </div>
      )}

      {!origin && !promo && !renderBackgroundTenant && showCoursesTenant && (
        <div className="col-md-7 d-none d-sm-block p-0">
          <Courses data={vitrineData} />
        </div>
      )}

      {(origin ||
        loginSettingsState.domain === 'hinode' ||
        promo ||
        renderBackgroundTenant) &&
        promoLeftBackground &&
        !isVocacao &&
        !litpass && (
          <div
            className="col-md-7 flex-column justify-content-start d-none d-md-flex bg-loginLeft big"
            style={{
              background:
                promoLeftBackground.backgroundColor ||
                loginSettingsState.primaryBackgroundColor ||
                '#FFF',
              padding: 0
            }}
          >
            <BoxPromo
              noContain={isContain}
              style={{
                backgroundImage: `url(${promoLeftBackground.backgroundImage ||
                  loginSettingsState.backgroundImage})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: bgDefaultHeight,
                maxWidth: '100%'
              }}
            />
          </div>
        )}

      {origin && !promoLeftBackground && (
        <div
          className={
            loginSettingsState.primaryBackgroundColor
              ? 'col-md-7 flex-column justify-content-start d-none d-md-flex big'
              : 'col-md-7 flex-column justify-content-start d-none d-md-flex bg-loginLeft big'
          }
          style={
            loginSettingsState.primaryBackgroundColor && {
              backgroundColor: loginSettingsState.primaryBackgroundColor,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              height: bgDefaultHeight,
              maxWidth: '100%'
            }
          }
        >
          {!loginSettingsState.logoUrl && litpass && (
            <img
              src={LitPassLogo}
              width={200}
              className="align-self-start"
              alt="lit-logo"
            />
          )}
          {!loginSettingsState.logoUrl && isVocacao && (
            <img
              src={LitOnlearning}
              width={200}
              className="align-self-start"
              alt="lit-logo"
            />
          )}
          {!litpass && !isVocacao && !loginSettingsState.logoUrl && (
            <img
              src={LitOnlearning}
              height={100}
              className="align-self-start ml-4 my-5"
              alt="lit-logo"
            />
          )}
          {loginSettingsState.logoUrl &&
          loginSettingsState.domain !== 'hinode' ? (
            <img
              src={loginSettingsState.logoUrl}
              height={100}
              className="align-self-start ml-4 my-5"
              alt="lit-logo"
            />
          ) : null}
          {loginSettingsState.domain !== 'hinode' ? (
            <NewCourses
              title={loginSettingsState.signinCarouselTitle}
              courses={loginSettingsState.signinCarouselItems}
            />
          ) : null}
        </div>
      )}

      {litpass && promo && (
        <div className="col-md-7 flex-column justify-content-start d-none d-md-flex bg-loginLeftPromo big">
          <BoxPromo style={{ backgroundImage: `url(${LitOnlearning})` }} />
        </div>
      )}

      {origin && isVocacao && (
        <div className="col-md-7 flex-column justify-content-start d-none d-md-flex bg-loginLeftPromo big">
          <BoxPromo
            noContain
            style={{ backgroundImage: `url(${imgVocaco})` }}
          />
        </div>
      )}

      {origin && litpass && (
        // on off promo background litpass
        <div className="col-md-7 flex-column justify-content-start d-none d-md-flex bg-loginLeftPromo big">
          <BoxPromo style={{ backgroundImage: `url(${LitOnlearning})` }} />
        </div>
      )}
    </>
  );
}

export default LeftSideBar;
