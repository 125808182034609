import React, {useMemo} from 'react';
import PropTypes from "prop-types";

import NavBarSuperior from "../NavBarSuperior/NavBarSuperior";
import Header from "./components/Header";
import Lanes from "./components/Lanes";
import FooterHome from '../Home/FooterHome';
import PageCalendar from './pages/Calendar';
import PageTimeline from './pages/Timeline';
import PageGradesAndAbsences from './pages/GradesAndAbsences';
import ParticipantsList from './pages/ParticipantsList';



import * as Styles from './styles';


const PAGES = {
  HOME: 'HOME',
  CALENDAR: 'CALENDAR',
  TIMELINE: 'TIMELINE',
  GRANDESANDABSENCES: 'GRANDESANDABSENCES',
  PARTICIPANTSLIST: 'PARTICIPANTSLIST'
}

function Screen({turma, activePage, selectPage, students}) {

  const renderPage = useMemo(() => {
    switch (activePage) {
      case PAGES.HOME:
        return <Lanes turma={turma} selectPage={selectPage}/>;
      case PAGES.CALENDAR:
        return <PageCalendar turma={turma} />;
      case PAGES.TIMELINE:
        return <PageTimeline turma={turma} />;
      case PAGES.GRANDESANDABSENCES:
        return <PageGradesAndAbsences turma={turma} />;
      case PAGES.PARTICIPANTSLIST:
        return <ParticipantsList students={students} />;
      default:
        return null;
    }
  }, [activePage, turma]);
  return (
   <>
    <NavBarSuperior />
    <Styles.Container>
    <Header turma={turma} activePage={activePage} selectPage={selectPage} students={students}/>
    {/* <Header
        hasActionTop={false}
        imagePage={turma.cover || imageDefault}
        textHeader={turma.name}
        size={SIZE.LARGE}
        bottomRight={
          <Actions
            itemsToRender={[
              {
                type: "button",
                action: () => selectPage(PAGES.CALENDAR),
                appearance: APPEARANCE.LINK,
                size: SIZE.SMALL,
                buttonText: 'Calendário',
                color: activePage === PAGES.CALENDAR ? '#27AAE1' : '#fff'
              },
              {
                type: "button",
                action: () => selectPage(PAGES.HOME),
                appearance: APPEARANCE.LINK,
                size: SIZE.SMALL,
                buttonText: 'Feed da Turma',
                color: activePage === PAGES.HOME ? '#27AAE1' : '#fff'
              },
              {
                type: "button",
                action: () => selectPage(PAGES.GRANDESANDABSENCES),
                appearance: APPEARANCE.LINK,
                size: SIZE.SMALL,
                buttonText: 'Notas e Faltas',
                color: activePage === PAGES.GRANDESANDABSENCES ? '#27AAE1' : '#fff'
              },
              // {
              //   type: "button",
              //   action: () => selectPage(PAGES.TIMELINE),
              //   appearance: APPEARANCE.LINK,
              //   size: SIZE.SMALL,
              //   buttonText: 'Timeline',
              //   color:  activePage === PAGES.TIMELINE ? '#333' : '#fff'
              // }
            ]}
          />
        }
      /> */}
      <Styles.Content>
      {renderPage}
      </Styles.Content>
    </Styles.Container>
    <div style={{marginTop: '40px'}}>
     <FooterHome />
    </div>
   </>
  );
}

Screen.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  turma: PropTypes.object.isRequired,
  activePage: PropTypes.string.isRequired,
  selectPage: PropTypes.func.isRequired
};

export default Screen;
