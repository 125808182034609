import swal from "@sweetalert/with-react";
import history from "../utils/history";

import { store as Store } from '../store'
import { Creators  } from '../store/ducks/newCourse/actions'

const handleRedirectMessage = mediaUrl => {
  swal({
    title: `Redirecionamento para link externo`,
    text: `O item que você selecionou encontra-se em outro local, por isso iremos lhe encaminhar para outro site em uma nova aba. Clique no botão "prosseguir" para avançar`,
    buttons: {
      confirm: {
        text: "PROSSEGUIR",
        value: true,
        visible: true,
        className: "btn-alert btn-alert-confirm-onboarding"
      },
      cancel: {
        text: "Cancelar",
        value: false,
        visible: true,
        className: "btn-alert"
      }
    }
  }).then(value => {
    if (value) {
      window.open(mediaUrl, "_blank");
    }
  });
};


export function openCallToAction(cta) {
  if (!cta) {
    return;
  }
  
  const { key } = cta;
  const { value } = cta;
  if (key === "redirect_to_course") {
    const [course, object] = value.split("@");
    history.push(
      {
        pathname: `/curso-novo/${course}`
      },
      {
        object
      }
    );
  } else if (key === "realizationarea") {
    history.push(`/webex/sso?realizationarea=${value}`);
  } else if (key === "redirect_to_assessment") {
    const { type, courseCrCode } = cta;
    const [course] = value.split("@");
    const redirectRoute = `${window.location.origin}/assessment-waiting-room?code=${value}&type=${type}&courseCrCode=${courseCrCode || course}`;
    window.open(redirectRoute, "__blank");
  } else if(key === "link") {
    const url = value.includes("https://") ? value : `https://${value}`;
    handleRedirectMessage(url);
  } else if(key === "redirect_to_old_course") {
    history.push(
      {
        pathname: `/curso/${value}`
      },
    );
  } else if(key === "redirect_to_tenant") {
    const [tenant, course, object] = value.split("@");
    const tenancy = JSON.parse(localStorage.getItem('tenancy')) || [];
    const foundTenant = tenancy.find(item => item.tenancy === tenant);
    if(foundTenant) {
      const redirectData = {
        tenant: foundTenant
      }
      if(course) {
        redirectData.course = course
      }

      if(object) {
        redirectData.object = object
      }
      Store.dispatch(Creators.redirectToTenant(redirectData))
    } else {
      swal({
        title: `Atenção!`,
        text: `Você não possui acesso a esta Área de Aprendizado, por favor entre em contato com o sac@lit.com.br.`,
        buttons: {
          confirm: {
            text: "OK",
            value: true,
            visible: true,
            className: "btn-alert btn-alert-confirm-onboarding"
          }
        }
      });
    }
  } else {
    console.log('cta não mapeado');
  }
}

