import React, { useState, useEffect } from 'react';
import swal from '@sweetalert/with-react';
import { toast } from 'react-toastify';
import NavBar from './subcomponents/Navbar';
import spinner from '../../images/paul_loading.gif';
import Instructions from './subcomponents/Instructions';
import Header from './subcomponents/Header';
import history from '../../utils/history';
import { gateway } from '../../utils/api';
import { toastDefaultOptions } from '../../utils/functions';
import { Container, ContainerBtn, Loading } from './styles';
import { LitButton } from '../shared/LitButton';

function AssessmentWaitingRoom({ location }) {
  const [loading, setLoading] = useState(true);
  const [code, setCode] = useState(null);
  const [data, setData] = useState(null);

  function notify(text, duration = 4000) {
    toast(text, {
      ...toastDefaultOptions,
      position: toast.POSITION.TOP_RIGHT,
      autoClose: duration
    });
  }

  function assessmentScheduledInvalidAlert() {
    swal({
      title: `Avaliação indisponível`,
      text: `Fora do prazo para realização da avaliação`,
      buttons: {
        confirm: {
          text: 'Ok',
          value: true,
          visible: true,
          className: 'btn-alert btn-alert-confirm-onboarding'
        }
      }
    }).then(() => {
      window.close();
    });
  }

  function goToAssessment() {

    try {
      const { hasEventValid = null } = data;

      if(hasEventValid){
        if(!hasEventValid.isStudentAbleToDoAssessment){
          assessmentScheduledInvalidAlert();
          return;
        }
      }
    } catch (error) {
      console.error('hasEventValid', error)
    }

    const params = new URLSearchParams(location.search);
    const assessmentType = params.get('type');
    const courseCode = params.get('courseCrCode');

    if (data.availabitity === 'Indisponível') {
      notify('Avaliação indisponível! Quantidade de tentativas excedidas');
      return;
    }

    let baseRedirectUrl = '';

    if (assessmentType === 'FORUM') {
      baseRedirectUrl = `assessment-forum?code=${code}`;
    } else {
      baseRedirectUrl = `assessment?code=${code}`;
    }

    baseRedirectUrl += assessmentType ? `&type=${assessmentType}` : '';
    baseRedirectUrl += courseCode ? `&course=${courseCode}` : '';

    history.replace(baseRedirectUrl);
  }

  function handleErrorAlert() {
    swal({
      title: `Ops, ocorreu um erro.`,
      text: `Não foi possível buscar os dados dessa avaliação.`,
      buttons: {
        confirm: {
          text: 'Continuar',
          value: true,
          visible: true,
          className: 'btn-alert btn-alert-confirm-onboarding'
        }
      }
    }).then(() => {
      window.close();
    });
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const assessmentCode = params.get("code");
    const courseCode = params.get("courseCrCode");

    async function loadAssessmentInfo() {
      try {
        const response = await gateway.get(
          `/webbff/lit_app_web/evaluation/${assessmentCode}/instructions?courseCode=${courseCode}`
        );
        setData(response);
        setLoading(false);
      } catch (error) {
        handleErrorAlert();
        setLoading(false);
      }
    }

    if (assessmentCode) {
      setCode(assessmentCode);
      loadAssessmentInfo();
    } else {
      handleErrorAlert();
    }
  }, []);

  return (
    <>
      {loading && (
        <Loading>
          <img
            alt="loading"
            style={{
              maxHeight: '85%',
              animation: 'loading-spin infinite 10s linear'
            }}
            src={spinner}
          />
        </Loading>
      )}

      {!loading && (
        <>
          <NavBar style={{ marginBottom: 0 }} />
          <Container>
            <Header name={data.name} type={data.type} />

              <div
                style={{
                  paddingLeft: 5,
                  paddingRight: 5
                }}
              >
                <p
                  style={{
                    fontSize: 24,
                    textAlign: 'justify'
                  }}
                >{data.evaluationRules}</p>
              </div>

            {data.type !== 'SELF_ASSESSMENT' && (
              <Instructions
                questionCount={data.questionCount}
                totalAverageTime={data.totalAverageTime}
                averageTimePerQuestion={data.averageTimePerQuestion}
                timeLimit={data.timeLimit}
                totalAttempts={data.totalAttempts}
                minimumCriterion={data.minimumCriterion}
                evaluationRules={data.evaluationRules}
                medias={data.medias}
                type={data.type}
                tags={data.tags}
                status={data.status}
                attemptsMade={data.attemptsMade}
                availabitity={data.availabitity}
              />
            )}
            <ContainerBtn>
              {(data.questionCount > 0) && <LitButton
                  disabled={loading}
                  style={{
                    textTransform: 'uppercase',
                    fontFamily: 'Dosis',
                    paddingLeft: 20,
                    paddingRight: 20,
                    fontSize: 20,
                  }}
                  onClick={goToAssessment}
                  className={`${loading && ' btn-loading'}`}
                >
                  <i className='fa fa-send' /> {data.status === 'Iniciado' ? 'Retomar' : 'Iniciar'}
                </LitButton>}
            </ContainerBtn>
          </Container>
        </>
      )}
    </>
  );
}

export default AssessmentWaitingRoom;
