import styled from 'styled-components';

export const  Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ParticipantsGrid = styled.div`
  display: grid;
  gap: 14px;
  margin: 14px 0;
  width: 100%;

  @media (min-width: 1850px) {
    grid-template-columns: repeat(7, 1fr);
  }

  @media (min-width: 1600px) and (max-width: 1849px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 1358px) and (max-width: 1599px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1357px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1023px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 479px) {
    grid-template-columns: 1fr;
  }
`;



export const  ParticipantCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  text-align: center;
`;

export const  ProfileImage = styled.img`
  width: 105px;
  height: 105px;
  border-radius: 200px;
  background-color: #ccc;
  margin-bottom: 10px;
`;

export const  Pagination = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;


export const PaginationButton = styled.button`
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: #27aae1;
`;
