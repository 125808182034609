import React, { useEffect } from "react";
import history from "../../utils/history";
import { gateway } from "../../utils/api";

export function CheckAddressOnboarding() {

  async function haveAddressCompletedOnOnboarding() {
    try {
      const response = await gateway.get("/onboarding/steps");
      const tenant = localStorage.getItem("tenant");
      const hasLitBusiness = tenant === 'LIT_BUSINESS';

      if (response[0].bf_concluded !== true && hasLitBusiness) {
        const filterStepPersonalData = response.find(item => item.wx_page === 'PERSONAL_DATA');

        const areFieldsPresent = (obj) => {
          const { wx_address, wx_addressnumber, wx_country, wx_zipcode } = obj.ox_personaldata;
          return wx_address && wx_addressnumber && wx_country && wx_zipcode;
        };

        if (filterStepPersonalData && !areFieldsPresent(filterStepPersonalData)) {
          history.push('/onboarding');
        }
      }
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    const { pathname } = window.location;
    const validPaths = ['/', '/home', '/consultaCpf', '/planos', '/payment','/cadastroPerfil', '/confirmarEmail', '/logout', '/invoicePaymentView'];
    const isPathValid = validPaths.includes(pathname);
 
    if (!isPathValid) {
      haveAddressCompletedOnOnboarding();
    }
  }, [window.location.pathname]);

  return null;
}
