/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import React, { useState, useEffect, useMemo } from "react";
import swal from "@sweetalert/with-react";
import _ from "lodash";
import Slider from "react-slick";
import { Line } from "rc-progress";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from '@material-ui/core/Tooltip';
import { gateway } from "../../../utils/api";
import imagens from "../../../utils/imagens";
import { abbreviateText } from "../../../utils/functions";

import { Container, BoxInfo, ProgressBarContainer } from "./styles";

import Loading from "../../Loading/Loading";
import AssessmentInfo from "./subcomponents/AssessmentInfo";
import AssessmentForumInfo from "./subcomponents/AssessmentForumInfo";
import GeneralGraphics from "./subcomponents/GeneralGraphics";

import { Creators as CourseActions } from "../../../store/ducks/newCourse/actions";

function Progress({ course }) {
  const [showSumary, setShowSumary] = useState(false);
  const [assessmentInfo, setAssessmentInfo] = useState({});
  const [assessments, setAssessments] = useState([]);
  const [assessmentsGroupSelected, setAssessmentsGroupSelected] = useState([]);
  const [weights, setWeights] = useState(null);
  const [forums, setForums] = useState([]);
  const [activeAssessment, setActiveAssessment] = useState(null);
  const [activeGroupAssessment, setActiveGroupAssessment] = useState(null);
  const [courseProgress, setCourseProgress] = useState(0);
  const [assessmentProgress, setAssessmentProgress] = useState(0);
  const [forumsData, setForumsData] = useState(null);
  const [graphics, setGraphics] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadingCertificate = useSelector(state => state.newCourse.loadingCertificate);
  const certificates = useSelector(state => state.newCourse.certificates);
  const skipCertificate = useSelector(state => state.newCourse.data.skipCertificate);
  const dispatch = useDispatch();

  const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    draggable: false
  };

  async function getAssessmentInfo(assessment) {
    setLoading(true);
    try {
      const url = `webbff/lit_app_web/evaluation/${assessment.cr_code}/summary`;
      const attempts = await gateway.get(url);
      const indvidualChart = graphics.find(item => item.type === "FINAL_EXAM");

      const charts = {
        performanceGraphic: null,
        highestGrade: null
      }

      if (indvidualChart) {
        charts.performanceGraphic = indvidualChart.performanceGraphic ? [...indvidualChart.performanceGraphic] : null;
        charts.highestGrade = indvidualChart.highestGrade ? [...indvidualChart.highestGrade] : null;
      }

      setAssessmentInfo({ ...assessment, attempts, graphics: charts });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      swal({
        title: `Ops, ocorreu um erro.`,
        text: `Não foi possível buscar os dados do curso.`,
        buttons: {
          confirm: {
            text: "Continuar",
            value: true,
            visible: true,
            className: "btn-alert btn-alert-confirm-onboarding"
          }
        }
      });
    }
  }

  function calculatePercentage(partialValue, totalValue) {
    return (100 * partialValue) / totalValue;
  }

  function calculateAssessmentsProgress(assessments) {
    let counter = 0;

    assessments.forEach(assessment => {
      if (assessment.progress === 100) {
        counter++;
      }
    });
    const assessmentProg = calculatePercentage(counter, assessments.length);

    return assessmentProg;
  }

  function getAssessments(data, level = 0, index = 0) {
    const children = data.children || [];
    let ucNumber = 0;
    const childrenAssessments = children.reduce((acc, item) => {
      if (item.type === "UC") {
        ucNumber += 1;
      }
      return acc.concat(getAssessments(item, level + 1, ucNumber));
    }, []);

    const filteredAssessments = (data.assessments || []).map(item => ({
      ...item,
      contentName: data.name,
      level,
      index
    }));

    const finalAssessments = [...filteredAssessments, ...childrenAssessments];

    return finalAssessments.filter(
      item =>
        item.type !== "SELF_ASSESSMENT" && item.type !== "EVALUATION_BOARD"
    );
  }

  function handleChangeGroupVisibility(assessmentGroup) {
    if (assessmentGroup.id === activeGroupAssessment) {
      setActiveGroupAssessment(null);
      setActiveAssessment(null);
      setAssessmentsGroupSelected([]);
      setShowSumary(false);
    } else {
      setActiveAssessment(null);
      setActiveGroupAssessment(assessmentGroup.id);
      if(assessmentGroup.assessments.length === 1){
        setAssessmentsGroupSelected([]);
        handleChangeVisibility(assessmentGroup.assessments[0])
      } else {
        setShowSumary(false);
        setAssessmentsGroupSelected(assessmentGroup.assessments);
      }
    }
  }

  function handleChangeVisibility(assessment) {
    if (assessment.cr_code === activeAssessment) {
      setActiveAssessment(null);
      setAssessmentInfo(null);
      setShowSumary(false);
    } else {
      setActiveAssessment(assessment.cr_code);
      getAssessmentInfo(assessment);
      setShowSumary(true);
    }
  }

  useEffect(() => {
    async function loadGraphics() {
      try {
        const response = await gateway.get(
          `/webbff/lit_app_web/course/graphic/${course.code}/graphic`
        );
        setLoading(false);
        setWeights(response.weights);

        setGraphics(response.graphics);
      } catch (error) {
        setLoading(false);
      }
    }

    setLoading(true);
    loadGraphics();
    
    const calculateCourseProgress = calculatePercentage(
      course.countFinishedObjects,
      course.countObjects
    );
    setCourseProgress(calculateCourseProgress);
    localStorage.removeItem('openProgress');

    const verifyAllAssessments = getAssessments(course);
    if (verifyAllAssessments.length > 0) {
      const assessmentProgressCalc = calculateAssessmentsProgress(
        verifyAllAssessments
      );

      const filteredForums = verifyAllAssessments.filter(
        item => item.type === "FORUM"
      );

      const assessmentWithoutForum = verifyAllAssessments.filter(
        item => item.type !== "FORUM"
      );

      setAssessmentProgress(assessmentProgressCalc);
      setAssessments(assessmentWithoutForum);
      setForums(filteredForums);
    }
    
    if(certificates[course.code] && !certificates[course.code].certificate) {
      const courseCode = _.get(course, 'code');
      dispatch(CourseActions.getCourseCertificateRequest(courseCode));
    }
  }, [course]);

  useEffect(() => {
    async function loadForumsData() {
      const response = await gateway.post(
        "/webbff/lit_app_web/course/graphic/forum/new",
        {
          codes: forums.map(forum => forum.cr_code)
        }
      );

      const forumToObj = forums.reduce((acc, current) => {
        acc[current.cr_code] = {
          ...current
        };
        return acc;
      }, {});

      const completedForuns = forums.reduce((acc, current) => {
        const completed = parseInt(current.progress, 10) === 100;
        if (completed) {
          return acc + 1;
        }
        return acc;
      }, 0);

      const formattedForumData = response.reduce(
        (acc, current) => {
          const isGraphicForumAdded = acc.graphics.find(item => item.label === _.get(forumToObj[current.assessmentCode], "contentName", ""))
          if(!isGraphicForumAdded) {
            acc.graphics.push({
              title: `M${_.get(forumToObj[current.assessmentCode], "index", 0)}`,
              label: _.get(forumToObj[current.assessmentCode], "contentName", ""),
              value: current.grade,
              index: _.get(forumToObj[current.assessmentCode], "index", 0)
            });
          } else if(isGraphicForumAdded.title === `M${_.get(forumToObj[current.assessmentCode], "index")}`) {     
            if(current.grade > isGraphicForumAdded.value) {
              isGraphicForumAdded.value = current.grade
            }
          }

          if (current.attempt) {
            acc.attempts.push({
              ...current.attempt,
              crCode: current.assessmentCode,
              value: current.grade,
              contentName: _.get(
                forumToObj[current.assessmentCode],
                "contentName",
                ""
              )
            });
          }

          return acc;
        },
        {
          /*
            This code value has been set only to meet the need for component 
            verification, but it should not be considered a valid code
          */
          cr_code: "Fóruns",
          name: "Fóruns",
          graphics: [],
          attempts: [],
          progress: parseInt((completedForuns * 100) / forums.length, 10)
        }
      );
      formattedForumData.graphics = formattedForumData.graphics.sort(
        (a, b) => a.index - b.index
      );

      setForumsData(formattedForumData);
    }
    if (forums && forums.length > 0) {
      loadForumsData();
    }
  }, [forums]);

  function downloadCertificate() {
    window.open(certificates[course.code].certificate.url, "_blank");
  }

  const assessmentsGrouped = useMemo(() => {

    const assessmentsTypes = {
      EXAM: 'Avaliações',
      FINAL_EXAM: 'Avaliação - Final',
      EXAMVMP: 'Vamos praticar?',
      DEFAULT: 'Avaliação'
    }

    const assessmentsPriorityList = {
      FINAL_EXAM: 0,
      EXAM: 1,
      EXAMVMP: 2,
      FORUM: 3
    }

    const assessmentsWithDiff = (assessments || []).map(item => {
      return {
        ...item,
        groupKey: item.tags.includes('VMP') ? `${item.type}VMP` : item.type
      }
    });

    const groupByType = _.groupBy(assessmentsWithDiff, 'groupKey')
    const groupByTypeList = Object.entries(groupByType);
    const grouped =  (groupByTypeList || []).map(item => {
      const itemAssessments = _.get(item, '1', []);
      const totalProgress = itemAssessments.reduce((acc, { progress }) => acc + progress, 0)
      
      const assessmentType = _.get(item, '0', 'DEFAULT');
      const itemOrder = assessmentsPriorityList[assessmentType];

      return {
        id: Math.random() * 2.5,
        name: assessmentsTypes[assessmentType],
        assessments: itemAssessments,
        progress: totalProgress && itemAssessments.length > 0
          ? parseFloat((totalProgress/itemAssessments.length)).toFixed(1)
          : 0,
        itemOrder
      }
    });

    const groupOrdered = _.orderBy(grouped, 'itemOrder');

    return groupOrdered;
  }, [assessments]);

  return (
    <Container>
      <div className="row justify-content-between">
        <div className={`col-${!skipCertificate ? '10' : '12'}`}>
          <ProgressBarContainer>
            <p>Progresso do curso</p>
            <Line
              percent={courseProgress}
              strokeWidth="1"
              trailWidth="1"
              strokeColor="#27aae1"
              trailColor="#f5f5f5"
            />

            <p>Progresso das avaliações</p>
            <Line
              percent={assessmentProgress}
              strokeWidth="1"
              trailWidth="1"
              strokeColor="#27aae1"
              trailColor="#f5f5f5"
            />
          </ProgressBarContainer>
        </div>
        {!skipCertificate && (
          <div className="col-2 align-self-center">
            <BoxInfo
              disabled={!certificates[course.code] || (certificates[course.code] && !certificates[course.code].certificate )}
              width={130}
              onClick={downloadCertificate}
            > 
              {loadingCertificate && (
                <Loading img width={20} height={20} />
              )}
  
              {!loadingCertificate && (
                <>
                  <p className="highlight">Certificado</p>  
                  {certificates[course.code] && certificates[course.code].certificate && (
                    <img alt="Certificado" src={imagens.certificateIcon} height={55} />
                  )}
                </>
              )}
            </BoxInfo>
          </div>
        )}
      </div>

      <Slider {...settings}>
        {assessmentsGrouped &&
          assessmentsGrouped.map((group, index) => {
            return (
              <BoxInfo
                key={index}
                disabled={loading}
                onClick={() => {
                  handleChangeGroupVisibility(group);
                }}
                active={group.id === activeGroupAssessment}
              >
                <p className="highlight">{group.name}</p>

                <Line
                  percent={group.progress}
                  strokeWidth="4"
                  trailWidth="4"
                  strokeColor={
                    group && group.id === activeGroupAssessment
                      ? "#27aae169"
                      : "#27aae1"
                  }
                  trailColor="#f5f5f5"
                />
              </BoxInfo>
            );
          })}

        {forumsData && (
          <BoxInfo
            disabled={loading}
            onClick={() => {
              if (showSumary && activeAssessment === "Fóruns") {
                setActiveAssessment(null);
                setActiveGroupAssessment(null);
                setAssessmentsGroupSelected(null);
                setAssessmentInfo(null);
                setShowSumary(false);
              } else {
                setActiveGroupAssessment(null);
                setAssessmentsGroupSelected(null);
                setAssessmentInfo(forumsData);
                setActiveAssessment("Fóruns");
                setShowSumary(true);
              }
            }}
            active={activeAssessment === "Fóruns"}
          >
            <p className="highlight">Fóruns</p>
            <Line
              percent={forumsData.progress}
              strokeWidth="4"
              trailWidth="4"
              strokeColor={
                activeAssessment === "Fóruns" ? "#27aae169" : "#27aae1"
              }
              trailColor="#f5f5f5"
            />
          </BoxInfo>
        )}
      </Slider>

      {<Slider {...settings}>
        {assessmentsGroupSelected &&
          assessmentsGroupSelected.map((assessment, index) => {
            return (
              <Tooltip title={(assessment.name).length > 20 && `${assessment.name}`} aria-label="add" placement="top">
                <BoxInfo
                  key={index}
                  disabled={loading}
                  onClick={() => {
                    handleChangeVisibility(assessment);
                  }}
                  active={assessment.cr_code === activeAssessment}
                  style={{ marginTop: 20 }}
                >
                  <p className="highlight">{abbreviateText(assessment.name, 20)}<br />
                    <small>M{parseInt(index+1)} - {abbreviateText(assessment.contentName, 20)}</small></p>

                  <Line
                    percent={assessment.progress}
                    strokeWidth="4"
                    trailWidth="4"
                    strokeColor={
                      assessment && assessment.cr_code === activeAssessment
                        ? "#27aae169"
                        : "#27aae1"
                    }
                    trailColor="#f5f5f5"
                  />
                </BoxInfo>
              </Tooltip>
            );
          })}
      </Slider>}

      {loading && (
        <div style={{ marginTop: "20px" }}>
          <Loading img width={30} height={30} />
        </div>
      )}
      {!loading && (
        <>
          {!showSumary && (
            <GeneralGraphics
              weights={weights}
              graphics={graphics}
              forumsData={forumsData}
            />
          )}

          {showSumary && (
            <>
              {activeAssessment === "Fóruns" ? (
                <AssessmentForumInfo courseCrCode={course.code} assessment={assessmentInfo} />
              ) : (
                <AssessmentInfo courseCrCode={course.code} assessment={assessmentInfo} />
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default Progress;
