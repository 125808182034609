import React, { useEffect } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Creators as CourseActions } from '../../../store/ducks/newCourse/actions';
import VideoV2 from './VideoV2';
import Video from './Video';

const SwitchableVideoPlayer = ({ selected, setPlayerPosition }) => {
  useEffect(() => {
    setPlayerPosition(_.get(selected, 'contentProgress.ox_status.nx_value', 0));
  }, [selected, setPlayerPosition]);

  return selected.vimeo_url ? (
    <VideoV2
      videoUrl={selected.vimeo_url}
      description={selected.shortDescription || selected.longDescription}
      selected={selected}
    />
  ) : (
    <Video />
  );
};

const mapStateToProps = (state) => ({
  selected: state.newCourse.selected,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CourseActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SwitchableVideoPlayer);
