import React, { useEffect } from 'react';

function DarkMode() {

    const darkModeStatus = localStorage.getItem("darkmode");

    const activeDarkMode = () => {
        if(darkModeStatus === "on"){
            localStorage.setItem("darkmode", "off")
        } else {
            localStorage.setItem("darkmode", "on")
        }
        window.location.reload();
    }

    useEffect(() => {
        if(darkModeStatus === "on"){
            document.body.classList.add("dark-mode");
        } else {
            document.body.classList.remove("dark-mode");
        }
    }, []);

    return <a
        style={{ marginTop: 2 }}
        className="nav-link"
        href="javascript:void(0)"
        target="_blank"
        onClick={activeDarkMode}>  
            <i className="fa fa-moon-o" aria-hidden="true" /> {darkModeStatus === 'on' ? 'Modo noturno' : ''}
        </a>;
}

export default DarkMode;