import styled, {keyframes} from 'styled-components'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Gradient = styled.div`
  background-color: #27aae1;
  filter: progid:DXImageTransform.Microsoft.gradient( GradientType=1, startColorstr=#27AAE1, endColorstr=#FCEE1E);
  background-image: -moz-linear-gradient(#27aae1 0%, #fcee1e 100%);
  background-image: -webkit-linear-gradient(#27aae1 0%, #fcee1e 100%);
  background-image: -ms-linear-gradient(#27aae1 0%, #fcee1e 100%);
  background-image: linear-gradient(#27aae1 0%, #fcee1e 100%);
  background-image: -o-linear-gradient(#27aae1 0%, #fcee1e 100%);
  background-image: -webkit-gradient(
    linear,
    color-stop(0%, #27aae1),
    color-stop(100%, #fcee1e)
  );
  cursor:pointer;
`

export const WrapperInfiniteScroll = styled.div`
  .infinite-scroll-component {
    overflow: hidden !important;
  }
`

export const LoadingWrapper = styled.div`
  margin: 5px;
  text-align: center;
  vertical-align: middle;

  svg {
    animation: ${rotate360} 1s linear infinite;
    margin-right: 5px;
  }
`;

export const StyleLane = styled.div`
  /* width: 265px; */
  /* margin: 10px; */
  padding: 0 5px;
  & + div {
    /* margin-left: 10px; */
  }
  position: relative;
  line-height: 100px;
  text-align: center;
  font-size: 26px;
  min-width: 300px;
  max-width: 300px;
`

export const NoImagemBook = styled(Gradient)`
  max-width: 100%;
  height: 300px;
  /* background: #27aae1; */

  display: flex;  
  flex-direction: column;
  justify-content: space-between;

  .info {
    display: flex;
    padding: 20px 10px;
 
    align-items: center;
    justify-content: center;
    flex: 1;
    svg {
      color: #fff
    }
    h3 {
      font-size: 20px;
      width: 100%;
     
      color: #fff !important;
    }
  }

  #author {
    background: #fff;
    font-size: 14px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    padding: 5px 5px;
    height: 60px;
    margin: 0;
  }
`

export const Loading = styled.div`
  position: fixed;
  z-index: 201;
  top: 66px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: blur(0px);
  background: transparent;
  height: calc((100% - 66px) - 75px);
`;

export const BodyDinamicBackgroundAndTextColor = styled.div`
  background-color: ${props => props.backgroundColor}
  h1, h2, h3, h4, h5, h6, p, span, div  {
    color: ${props => props.textColor}
  }
`;
