import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 56px;
`;

export const Content = styled.div`
  width: 94%;
  /* max-width: 2000px; */
  padding: 10px 
  margin: 0 auto;
  clear: both;
  @media(max-width: 992px) {
    padding-top: 30px;
  } 

`
