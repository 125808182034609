import React, { useMemo } from 'react';
import _ from 'lodash';

import * as Styles from './styles';
import { renderGradeIcon } from '../../../utils/functions';

function CourseAssessmentItem({ data, courseCrCode }) {
  function openAttachment() {
    if (!data.available) {
      return; 
    }
    const crCode = _.get(data, 'cr_code', ''); 
    const type = _.get(data, 'type', '');
    const redirectRoute = `${window.location.origin}/assessment-waiting-room?code=${crCode}&type=${type}&courseCrCode=${courseCrCode}`;
    window.open(redirectRoute, '__blank');
  }

  const isApproved = useMemo(() => {
    return Number(data.grade) >= (Number(data.approvalMinGrade || 0) / 10)
  }, [data]);

  const status = useMemo(() => {
    return data.status
  }, [data]);

  const code = useMemo(() => {
    return _.get(data, 'cr_code', '');
  }, [data]);

  const available = useMemo(() => {
    return _.get(data, 'available', true);
  }, [data]);

  const assessmentScheduleDate = useMemo(() => {
    const startDate = _.get(data, 'startDate', null);
    const endDate = _.get(data, 'endDate', startDate);
  
    const text =
      startDate && new Date(startDate) > new Date() ? 'Início' : 'Fim';
  
    const formatDateTime = (date) => {
      if (!date) return null;
  
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');
  
      return `${day}/${month}/${year} às ${hours}:${minutes}h`;
    };
  
    return {
      date: formatDateTime(endDate),
      text,
      isValid: endDate,
    };
  }, [data]);
  

  const renderCircle = () => {
    let classIcon = 'circle circle-with-grade';

    if (status === 'DONE_SELF_ASSESSMENT' || status === 'DONE_REPROVED') {
      classIcon = 'circle circle-with-grade assessment';
    }
    if (status === 'DONE' || status === 'DONE_REPROVED') {
      classIcon = 'circle circle-with-grade active';
    }

    if(isApproved && status === "DONE"){
      classIcon += ' bg-icon-green ';
    }

    if(data && data.grade === null || data.grade === 'null') {
      return (
        <div 
          id={`course-object-${data.code}`} 
          style={{border: 0, background: 'transparent', marginRight: '2px'}}
          className='circle circle-with-grade'
        >
        </div>
      )
    }

    return (
      <div id={`course-object-${data.code}`} className={classIcon}>
        {status === "DONE" || status === 'DONE_REPROVED' 
          ? data.grade && data.grade.toFixed(1) 
          : "S/N"}
      </div>
    );
  };

  const generateIcon = () => {
    return "graduation-cap";
  }

  return (
    <Styles.Container id={code} available={available}>


    <Styles.ContentItem className="list-child">

      <Styles.ContentItemChild
          available={available}
          title={data.availabilityDescription}
          role="button"
          onClick={openAttachment}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginLeft: -8
          }}
        >

              {renderCircle()}

              <Styles.ContainerIcon isApproved={isApproved} status={status}>
                  <i className={`fa fa-${generateIcon()}`} />
              </Styles.ContainerIcon>

              <span className="info">{data.name}</span>
                  
          </Styles.ContentItemChild>
      </Styles.ContentItem>

      {assessmentScheduleDate.isValid && (
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          {available && (
            <>
              <span style={{ marginRight: 10, marginLeft: 20 }}>
                {assessmentScheduleDate.text}: 
                <span style={{ fontWeight: 'bold'}}>{` ${assessmentScheduleDate.date}`}</span>
                
              </span>
              <span style={{ marginRight: 20, marginLeft: 20 }}>{renderGradeIcon('OPEN')}</span>
            </>
          )}
          {!available && (
            <>
              <span style={{ marginRight: 10, marginLeft: 20 }}>
                {assessmentScheduleDate.text}: 

                <span style={{ fontWeight: 'bold'}}>{` ${assessmentScheduleDate.date}`}</span>

              </span>
              <span style={{ marginRight: 20, marginLeft: 20 }}>{renderGradeIcon('LOCKED')}</span>
            </>
          )}
        </div>
      )}
    </Styles.Container>
  );
}

export default CourseAssessmentItem;
