import styled from 'styled-components';

export const TableWrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
  padding: 20px;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
`;

export const StyledTh = styled.th`
  background-color: ${(props) => props.backgroundColor || '#f2f2f2'};
  color: white;
  font-weight: bold;
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

export const StyledTd = styled.td`
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

export const StyledTr = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const TableTitle = styled.h2`
  margin-bottom: 10px;
  color: #333;
`;

export const MessageWrapper = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #555;
`;