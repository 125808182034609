import _ from 'lodash';
import React from 'react';

import swal from '@sweetalert/with-react';
import { findDOMNode } from 'react-dom';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { bindActionCreators } from 'redux';
import screenfull from 'screenfull';
import { Creators as CourseActions } from '../../../store/ducks/newCourse/actions';
import api, { gateway } from '../../../utils/api';
import {
  KALTURA_EXPIRY,
  KALTURA_PARTNER_ID,
  KALTURA_PRIVILEGES,
  KALTURA_SERVICE_URL,
  KALTURA_SESSION_TYPE,
  KALTURA_USER_ID,
  SECRET
} from "../../../utils/constants";
import history from '../../../utils/history';
import Duration from './Duration';
import ModalShare from './ModalShare';

import imagens from '../../../utils/imagens';
import ContentModalLitPass from '../../shared/ContentModalLitPass';
import ViewEditor from '../../shared/ViewEditor';

import * as Styles from './styles';

const style = {
  video: {
    width: '100%',
    height: '100%'
  }
};

const mediasTypes = {
  1: 'VIDEO',
  5: 'AUDIO'
}

const speeds = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2];
const counterMaxValue = 5;
let kalturaClient = null;

class Podcast extends React.Component {
  constructor(props) {
    super(props);

    this.debouncedSeekChange = _.debounce((selected) => this.saveProgress(selected, 'ADVANCED'), 500);

    this.state = {
      playing: true,
      volume: 1,
      replay: false,
      played: 0,
      key: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      shareScreen: false,
      counterTime: 0,
      like: null,
      idReaction: null,
      finished: false,
      playedSeconds: null,
      idProfile: parseInt(localStorage.getItem('idProfile'), 10),
      isCounting: false,
      isEndVideo: false,
      isFullScreen: false,
      isMouseMoving: false,
      counter: counterMaxValue,
      timer: 0,
      coursePlayersPositions: {},
      playerUrl: props.selected.url,
      flavors: [],
      selectedFlavor: null,
      ready: false,
      showAlertAudio: false,
      mediaType: null
    };
    this.setMouseMove = _.throttle(this.setMouseMove.bind(this), 1000);
  }

  getDataMedia = () => {
    // a sequencia de codigos a partir da função abaixo
    // sera extraida para o backend num futuro próximo
    const { playerUrl } = this.state;
    if (this.isKalturaUrl(playerUrl)) this.session_start();
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://use.typekit.net/foobar.js';
    script.async = true;
    this.onStart();
    this.findReaction();
    this.getDataMedia();
    screenfull.on('change', () => {
      this.toggleFullScreenState();
    });
    document.addEventListener('keydown', this.handlePlayerOrPauseByEventKey);
  }

  componentWillReceiveProps(nextProps) {
    const { actionPlayVideo } = nextProps;
    const { setActionPlayVideo, selected } = this.props;
    if (actionPlayVideo === 'PLAY') {
      this.onPlay('RESUMED');
      setActionPlayVideo(null);
    }
    if (actionPlayVideo === 'PAUSE') {
      this.onPause('PAUSED');
    }

    if (selected.code !== nextProps.selected.code) {
      this.setState({ ready: false });
      this.saveProgress(selected, 'CHANGED OBJECT');
      this.getDataMedia();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { playerPosition, userRequestSeekPlayer, selected } = this.props;
    if (prevProps.selected.url !== selected.url) {
      this.setNewPlayUrl(selected.url);
    }

    if (userRequestSeekPlayer) {
      this.setPage(playerPosition);
    }

    if (prevState.ready !== this.state.ready) {
      if (this.state.ready) {
        this.getCurrentPosition();
      }
    }
  }

  setNewPlayUrl = playerUrl => {
    this.setState({ playerUrl });
  };

  componentDidUnMount() {
    this.setState({ playerUrl: '', mediaType: null });
  }

  setMouseMove(e) {
    e.preventDefault();

    if (this.state.isMouseMoving || !this.state.isFullScreen) {
      return;
    }

    this.setState({ isMouseMoving: true });

    let timeout;
    (() => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.setState({ isMouseMoving: false });
      }, 4000);
    })();
  }

  isKalturaUrl(url) {
    if (!url) {
      return false;
    }

    return url.includes('https://cdnapisec.kaltura.com');
  }

  onSuccessGetFlavor = (success, data) => {
    if (!success) return;

    const { flavors } = this.state;

    const formattedFlavors = [
      ...flavors,
      {
        id: data.id,
        name: data.name,
        height: data.height
      }
    ];

    const newFlavors = formattedFlavors
      .filter(flavor => flavor.name !== 'Source')
      .sort(function (a, b) {
        return b.height - a.height;
      });

    this.setState({ flavors: newFlavors });
  };

  getFlavorsFormatted = data => {
    const flavorsArray = data.split(',');
    flavorsArray.forEach(flavorId => {
      window.KalturaFlavorParamsService.get(flavorId)
        .completion(this.onSuccessGetFlavor)
        .execute(kalturaClient);
    });
  };

  onSuccessGetMedia = (success, data) => {
    if (!success) return;
    const { mediaType } = data;
    const flavors = data.flavorParamsIds;
    const flavorsArray = flavors.split(',');
    const last = [...flavorsArray].pop();
    const { playerUrl } = this.state;
    const newUrl = `${playerUrl}/flavorParamId/${flavors}`;
    if (mediaType === 1) {
      this.getFlavorsFormatted(flavors);
      this.setState({
        playerUrl: newUrl,
        selectedFlavor: Number(last),
        mediaType: mediasTypes[mediaType]
      });
    } else {
      this.setState({
        playing: true,
        mediaType: mediasTypes[mediaType]
      });
    }
  };

  extractEntryId(kalturaUrl) {
    const entryIdIndex = 6;
    const kalturaUrlPattern = /(https:\/\/cdnapisec.kaltura.com\/p\/)(\w+)(\/sp\/)(\w+)(\/playManifest\/entryId\/)(\w+)(\/format\/url\/protocol\/https)/g;
    const patternExecutionReturn = kalturaUrlPattern.exec(kalturaUrl);
    if (patternExecutionReturn[entryIdIndex]) {
      return patternExecutionReturn[entryIdIndex];
    }
  }

  session_start_cb = (success, ks) => {
    const { playerUrl } = this.state;
    kalturaClient.setKs(ks);
    const entryId = this.extractEntryId(playerUrl);

    window.KalturaMediaService.get(entryId)
      .completion(this.onSuccessGetMedia)
      .execute(kalturaClient);
  };

  session_start() {
    const config = new window.KalturaConfiguration(KALTURA_PARTNER_ID);
    config.serviceUrl = KALTURA_SERVICE_URL;

    const client = new window.KalturaClient(config);

    kalturaClient = client;

    window.KalturaSessionService.start(
      SECRET,
      KALTURA_USER_ID,
      KALTURA_SESSION_TYPE,
      KALTURA_PARTNER_ID,
      KALTURA_EXPIRY,
      KALTURA_PRIVILEGES
    )
      .completion(this.session_start_cb)
      .execute(client);
  }

  setPage = newTime => {
    if (newTime) {
      const { setUserRequestSeekPlayer } = this.props;
      const seconds = this.formatedTimeToSeconds(newTime);
      this.setState({ startFrom: seconds });
      this.player.seekTo(seconds);
      setUserRequestSeekPlayer();
    }
  };

  componentWillUnmount() {
    this.clearInterval();
    const { selected } = this.props
    this.saveProgress(selected, 'EXIT');
  }

  clearInterval = () => {
    const { interval } = this.state;
    if (interval) clearInterval(interval);
  };

  getCurrentPosition = () => {
    const { coursePlayersPositions } = this.state;
    const { selected, setPlayerPosition, autoplayNextVideos } = this.props;
    /* The progress is retrieved from local memory first, and only if the previous value is null it gets from backend.
    That means it may have syncronization problems if the user is watching the course in other device for example. */
    let progressValue =
      coursePlayersPositions[selected.id] ||
      _.get(selected, 'contentProgress.ox_status.nx_value', 1);
    progressValue = this.formattedTime(progressValue)
    this.player.seekTo(progressValue || 1);
    setPlayerPosition(progressValue);;
    // TODO: we need to find another way for waiting to play the video on the correct moment. Let's remove this setTimeout.
    setTimeout(() => {
      this.setState({
        playing: autoplayNextVideos,
        replay: false
      });
    }, 2000);
  };

  formattedTime = formatedTime => {
    if (formatedTime && typeof formatedTime === 'string') {
      const splitCurrentPosition = formatedTime.split(':');
      const minCurrentPosition = parseInt(splitCurrentPosition[0], 10);
      const secCurrentPosition = parseInt(splitCurrentPosition[1], 10);

      return minCurrentPosition * 60 + secCurrentPosition;
    }
    return formatedTime;
  };

  formatedTimeToSeconds = formatedTime => {
    if (formatedTime && typeof formatedTime === 'string') {
      const splitCurrentPosition = formatedTime.split(':');
      const minCurrentPosition = parseInt(splitCurrentPosition[0], 10);
      const secCurrentPosition = parseInt(splitCurrentPosition[1], 10);

      return minCurrentPosition * 60 + secCurrentPosition;
    }
    return null;
  };

  findReaction = () => {
    const { selected, idCourse } = this.props;
    const { idReaction } = this.state;
    const obj = {
      idReaction,
      idRA: localStorage.getItem('idRA'),
      totvsCourse: idCourse,
      bbChildren: selected.idChildren,
      spxAttachment: selected.idAttachment,
      isLike: null
    };
    console.log('--------------------------------------------------------------------------------------')

    this.setState({
      like: null,
      idReaction: null
    });
    api
      .post('doFindReaction', obj)
      .then(res => {
        if (res.message) return;
        this.setState({
          like: res.isLike,
          idReaction: res.idReaction
        });
      })
      .catch(err => console.log(err.response));
  };

  like = () => {
    const { idReaction } = this.state;
    const { idCourse, selected } = this.props;
    const obj = {
      idReaction,
      idRA: localStorage.getItem('idRA'),
      totvsCourse: idCourse,
      bbChildren: selected.idChildren,
      spxAttachment: selected.idAttachment,
      isLike: true
    };

    api
      .post('doReactionLike', obj)
      .then(res => {
        this.setState({
          like: res.isLike,
          idReaction: res.idReaction
        });
        this.findReaction();
      })
      .catch(err => console.error(err));
  };

  deslike = () => {
    const { idReaction } = this.state;
    const { idCourse, selected } = this.props;

    const obj = {
      idReaction,
      idRA: localStorage.getItem('idRA'),
      totvsCourse: idCourse,
      bbChildren: selected.idChildren,
      spxAttachment: selected.idAttachment,
      isLike: false
    };
    api
      .post('doReactionDeslike', obj)
      .then(res => {
        this.setState({
          like: res.isLike,
          idReaction: res.idReaction
        });
        this.findReaction();
      })
      .catch(err => console.error(err));
  };

  sendObjectProgress = (selected, idProfile, action, status, playbackRate) => {
    const { courseSession, setPlayerPosition } = this.props;
    const { code, courseCode } = selected;
    const sessionCourse = localStorage.getItem('session') || courseSession;

    const time = this.getPlayedTime();

    setPlayerPosition(time);

    gateway.post("/webbff/lit_app_web/course_progress/course_progress_object/", {
      selected: { code, courseCode },
      idProfile,
      courseSession: sessionCourse,
      action,
      status,
      type: "VIDEO",
      mode: playbackRate,
      position: time.split(':').reduce((acc, time) => 60 * acc + +time) || 0,
    });
  }

  saveProgress = (selected, action) => {
    const { idProfile, coursePlayersPositions, duration, playbackRate } = this.state;
    const { setPlayerPosition, setDisciplineProgress } = this.props;
    const time = this.getPlayedTime();

    setPlayerPosition(time);
    if (time !== '0:00' && time !== '00:00') {

      const progress = {
        idProfile,
        hierarchy: selected.hierarchy,
        contentCode: selected.code,
        courseCode: selected.courseCode,
        position: time.split(':').reduce((acc, time) => 60 * acc + +time),
        status: 'IN PROGRESS'
      };

      /* Needs to save the progress in coursePlayersPositions because the progress is not saved in the props, it is only saved on backend.
      Without it, the progress seems to not be saved when changing the video that is playing, except when the window is refreshed. */
      this.setState({
        coursePlayersPositions: {
          ...coursePlayersPositions,
          [selected.id]: progress.position === Math.trunc(duration) ? 0 : progress.position
        }
      });

      setDisciplineProgress(selected.idDiscipline);
      gateway
        .post('/webbff/lit_app_web/progress/saveProgress', progress)
        .then(res => {
          console.log('RESPOSTA endOfFile', res.data);
        })
        .catch(err => console.log(err));
    }

    this.sendObjectProgress(selected, idProfile, action, 'IN PROGRESS', playbackRate);
  };

  finishAttachment = (action) => {
    this.clearInterval();
    const { idProfile, playbackRate } = this.state;
    const {
      selected,
      incrementCourseProgress,
      setDisciplineProgress
    } = this.props;

    const progress = {
      idProfile,
      hierarchy: selected.hierarchy,
      contentCode: selected.code,
      courseCode: selected.courseCode,
      position: 0,
      status: 'DONE'
    };

    const objectStatusProgress = _.get(selected, 'contentProgress.ox_status.wk_status', 'IN_PROGRESS');

    this.sendObjectProgress(selected, idProfile, action, 'DONE', playbackRate);

    gateway
      .post('/webbff/lit_app_web/progress/saveProgress', progress)
      .then((response) => {
        document
          .getElementById(`course-object-${selected.code}`)
          .classList.add('active');

        if (objectStatusProgress !== 'DONE') {
          const progress = _.get(response, 'progress', []);
          const foundObject = progress.find(p => p.rk_reference === selected.code);
          const status = _.get(foundObject, 'wk_status');
          incrementCourseProgress(status);
        }

        setDisciplineProgress(selected.idDiscipline);

        if (this.isToPlayNextVideo()) {
          this.startCounter();
        }

        this.setState({ finished: true, replay: true });
      })
      .catch(err => console.log(err));
  };

  isToPlayNextVideo = () => {
    const allowedTypes = ['VOD', 'VIDEO'];
    const { navigationContent, autoplayNextVideos } = this.props;
    const type =
      navigationContent.next && navigationContent.next.media
        ? navigationContent.next.media.type
        : null;
    const isTypeVideo = allowedTypes.includes(type);
    return isTypeVideo && autoplayNextVideos;
  };

  startCounter = () => {
    const { counter, timer, replay } = this.state;
    this.setState({ isEndVideo: true });
    if (timer === 0 && counter > 0 && replay === false) {
      this.setState({
        timer: setInterval(this.countDown, 1000),
        isCounting: true
      });
    }
  };

  countDown = () => {
    const { counter } = this.state;
    const seconds = counter - 1;
    this.setState({ counter: seconds });
    // Check if we're at zero.
    if (seconds === 0) {
      this.getNextVOD();
    }
  };

  onRedirect = course => {
    const id = course.idCourse || course.attach.id;
    swal.close();
    history.push({ pathname: `/curso/${id}` });
  };

  getNextVOD = () => {
    const {
      course,
      progress,
      warningDisplayed,
      setWarningDisplayed
    } = this.props;
    if (this.isToPlayNextVideo()) {
      if (!course.activeCourseSeason && course.mustRequestCourseActivation) {
        if (
          course.countFinishedObjects >= 2 &&
          !progress.countFinishedObjects &&
          !warningDisplayed
        ) {
          setWarningDisplayed(true);
          swal({
            content: <ContentModalLitPass redirect={this.onRedirect} />,
            buttons: {
              cancel: {
                text: 'Cancelar',
                value: false,
                visible: true,
                className: 'btn-alert'
              },
              confirm: {
                text: 'Confirmar',
                value: true,
                visible: true,
                className: 'btn-alert btn-alert-confirm'
              }
            }
          }).then(value => {
            if (value) {
              this.startNextVod();
            } else {
              this.finishTimerNextVod();
            }
          });
        } else if (progress.countFinishedObjects >= 2 && !warningDisplayed) {
          setWarningDisplayed(true);
          swal({
            content: <ContentModalLitPass redirect={this.onRedirect} />,
            buttons: {
              cancel: {
                text: 'Cancelar',
                value: false,
                visible: true,
                className: 'btn-alert'
              },
              confirm: {
                text: 'Confirmar',
                value: true,
                visible: true,
                className: 'btn-alert btn-alert-confirm'
              }
            }
          }).then(value => {
            if (value) {
              this.startNextVod();
            } else {
              this.finishTimerNextVod();
            }
          });
        } else {
          // setWarningDisplayed(true)
          this.startNextVod();
        }
      } else {
        this.startNextVod();
      }
    }
    this.finishTimerNextVod();
  };

  finishTimerNextVod = () => {
    const { timer } = this.state;
    clearInterval(timer);
    this.setState({
      counter: counterMaxValue,
      isCounting: false,
      timer: 0
    });
  };

  startNextVod = () => {
    const { navigationContent, setCourseSelected } = this.props;
    setCourseSelected(navigationContent.next);
    this.onStart();
  };

  getPlayedTime = () => {
    const { duration, played } = this.state;
    const date = new Date(duration * played * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = this.pad(date.getUTCSeconds());

    if (hh) {
      return `${hh}:${this.pad(mm)}:${ss}`;
    }

    return `${mm}:${ss}`;
  };

  pad = string => {
    return `0${string}`.slice(-2);
  };

  handleChangeResolution = flavorId => {
    const { selected } = this.props;
    const newUrl = `${selected.url}/flavorParamId/${flavorId}`;
    this.setState({ playerUrl: newUrl, selectedFlavor: flavorId });
  };

  onPlay = (action = 'STARTED') => {
    this.clearInterval();
    const { selected } = this.props
    const { playbackRate, idProfile } = this.state;

    this.sendObjectProgress(selected, idProfile, action, 'IN PROGRESS', playbackRate);

    const interval = setInterval(() => this.saveProgress(selected, action), 120000);
    this.setState({ playing: true, interval });
    this.checkAudio();
  };

  onPause = () => {
    const { loop, playedSeconds, duration } = this.state;
    this.clearInterval();
    this.setState({ playing: loop });

    const videoNotEnded = Math.trunc(playedSeconds) < Math.trunc(duration);

    if (videoNotEnded) {
      const { selected } = this.props;
      this.saveProgress(selected, 'PAUSED');
    }
  };

  onEnded = () => {
    this.setState({ playing: false });
    this.finishAttachment('DONE');
  };

  onProgress = state => {
    const { seeking, counterTime } = this.state;
    if (!seeking) {
      this.setState(state);
    }

    this.setState({ counterTime: counterTime + 1 });
  };

  playPause = () => {
    const { playing, replay } = this.state;
    const { setPlayerPosition } = this.props;
    if (replay) {
      this.player.seekTo(0);
      setPlayerPosition(0);
      this.setState({
        finished: false,
        isEndVideo: false,
        playing: true,
        replay: false
      });
    } else {
      this.setState({ playing: !playing, replay: false });
    }
  };

  toggleMuted = () => {
    const { muted, setVideoMuted } = this.props;
    setVideoMuted(!muted);
  };

  onSeekMouseDown = () => {
    this.setState({ seeking: true });
  };

  onSeekChange = e => {
    const { selected } = this.props;
    this.setState({
      replay: false,
      isEndVideo: false,
      played: parseFloat(e.target.value)
    });
    this.debouncedSeekChange(selected);
  };

  onSeekMouseUp = e => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  toggleFullScreen = () => {
    screenfull.toggle(findDOMNode(this.videoWrapper));
  };

  toggleFullScreenState = () => {
    this.setState({
      isFullScreen: screenfull.isFullscreen
    });
  };

  onClickFullscreen = () => {
    this.toggleFullScreen();
  };

  onStart = () => {
    this.clearInterval();
    this.getCurrentPosition();
    this.setState({ replay: false });
  };

  onReady = () => {
    this.setState({ ready: true });
  }

  shareMenu = () => {
    const { shareScreen, isFullScreen } = this.state;

    if (isFullScreen) {
      this.toggleFullScreen();
    }

    this.setState({ shareScreen: !shareScreen });
  };

  setVolume = e => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  ref = player => {
    this.player = player;
  };

  videoWrapperRef = videoWrapper => {
    this.videoWrapper = videoWrapper;
  };

  _handleContextMenu = e => {
    e.preventDefault();
  };

  downloadAnexo = ({ path }) => {
    if (path) {
      const linkDownload = document.createElement('a');
      linkDownload.setAttribute('download', '');
      linkDownload.setAttribute('href', path);
      linkDownload.setAttribute('target', '_blank');
      linkDownload.click();
    }
  };

  checkAudio = () => {
    const { playing } = this.state;
    const { muted } = this.props;
    if (playing && muted) {
      this.setState({ showAlertAudio: true })
    } else {
      this.setState({ showAlertAudio: false })
    }
  }

  handlePlayerOrPauseByEventKey = (event) => {
    const { isFullScreen } = this.state
    if (event.key === ' ' && isFullScreen) {
      this.playPause()
    }
  }

  render() {
    const {
      playing,
      volume,
      played,
      duration,
      playedSeconds,
      playbackRate,
      finished,
      replay,
      like,
      shareScreen,
      isEndVideo,
      counter,
      isCounting,
      playerUrl,
      flavors,
      selectedFlavor,
      isFullScreen,
      isMouseMoving,
      showAlertAudio,
      mediaType
    } = this.state;
    const { showDuvidas, autoplayNextVideos, muted, setVideoMuted, course, selected } = this.props;
    let bg
    const coverItem = _.get(selected, 'cover', null)
    if (!coverItem) {
      bg = _.get(course, 'media.path', '')
    } else {
      bg = coverItem
    }

    const knowMoreList = this.props.selected.knowMore;

    return (
      <div
        className="d-flex flex-column"
        onContextMenu={this._handleContextMenu}
        ref={this.videoWrapperRef}
        onMouseMove={e => this.setMouseMove(e)}
      >
        {autoplayNextVideos && isEndVideo && counter > 0 && isCounting ? (
          <Styles.FloatingMsg>
            <div
              style={{ backgroundColor: '#d8d8d8', padding: '2.5em' }}
              className="w-50"
            >
              <div className="row">
                <div className="col-12">
                  <span>
                    <strong>
                      O próximo vídeo inicia em {counter} segundos
                    </strong>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <button
                    onClick={this.getNextVOD}
                    className="btn btn-blue btn-block"
                    type="button"
                  >
                    Próximo
                  </button>
                </div>
                <div className="col-6">
                  <button
                    onClick={this.finishTimerNextVod}
                    className="btn btn-cancel btn-block"
                    type="button"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </Styles.FloatingMsg>
        ) : (
          ''
        )}

        {showAlertAudio && <Styles.FloatingMsg>
          <div
            style={{ backgroundColor: '#d8d8d8', padding: '2.5em' }}
            className="w-75"
          >
            <div className="row">
              <div className="col-12 text-center mb-2">
                <span>
                  <h4>
                    Ative o som!
                  </h4>
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <button
                  onClick={() => {
                    this.setState({
                      showAlertAudio: false,
                      volume: 0.5
                    })
                    this.toggleMuted();
                  }}
                  className="btn btn-blue btn-block"
                  type="button"
                >
                  Ativar áudio
                </button>
              </div>
              <div className="col-6">
                <button
                  onClick={() => {
                    this.setState({ showAlertAudio: false })
                  }}
                  className="btn btn-cancel btn-block"
                  type="button"
                >
                  Continuar sem áudio
                </button>
              </div>
            </div>
          </div>
        </Styles.FloatingMsg>}

        <div
          className="player-wrapper"
        >
          <div className='player-wrapper-audio' style={{ backgroundImage: `url(${bg})` }}></div>
          <ReactPlayer
            url={playerUrl}
            className="react-player"
            style={style.video}
            ref={this.ref}
            onPlay={() => this.onPlay('RESUMED')}
            onPause={this.onPause}
            onReady={this.onReady}
            playbackRate={playbackRate}
            onDuration={dur => this.setState({ duration: dur })}
            onProgress={this.onProgress}
            onEnded={this.onEnded}
            muted={muted}
            playing={playing}
            volume={volume}
          />
        </div>

        <div
          className={`video-controls-wrapper ${isFullScreen ? 'fullscreen' : ''
            } ${!isMouseMoving ? 'hidden' : ''}`}
        >
          <input
            type="range"
            min={0}
            max={1}
            step="any"
            value={played}
            onMouseDown={this.onSeekMouseDown}
            onChange={this.onSeekChange}
            onMouseUp={this.onSeekMouseUp}
          />
          <div className="video-control d-flex align-items-center">
            {replay ? (
              <a
                data-tip="Play"
                role="button"
                className="btn"
                onClick={this.playPause}
              >
                <i className="fa fa-repeat" aria-hidden="true" />
              </a>
            ) : (
              <a
                data-tip="Play"
                role="button"
                className="btn"
                onClick={this.playPause}
              >
                {playing ? (
                  <i className="fa fa-pause" aria-hidden="true" />
                ) : (
                  <i className="fa fa-play" aria-hidden="true" />
                )}
              </a>
            )}
            <div
              data-tip="Liga, aumenta e diminui som e desliga"
              className="volume d-flex align-items-center"
            >
              <a role="button" className="pr-4 pl-2" style={{ marginTop: -3 }} onClick={this.toggleMuted}>
                {muted ? (
                  <img src={imagens.mute} />
                ) : (
                  <img src={imagens.volume} />
                )}
              </a>
              <div className="volume-range">
                <input
                  id="volume-range"
                  type="range"
                  min={0}
                  max={1}
                  step="any"
                  value={volume}
                  onChange={e => {
                    this.setVolume(e);
                    setVideoMuted(false);
                  }}
                />
              </div>
            </div>
            <Duration seconds={duration * played} /> /{' '}
            <Duration seconds={duration} />
            <div className="d-flex align-items-center ml-auto p-2">
              {(playedSeconds / duration >= 0.8 || finished === true) && (
                <div>
                  <a
                    className={`btn reaction ${like === true && 'active'}`}
                    role="button"
                    onClick={this.like}
                  >
                    <i className="fa fa-thumbs-up" />
                  </a>
                  <a
                    className={`btn reaction ${like === false && 'active'}`}
                    role="button"
                    onClick={this.deslike}
                  >
                    <i className="fa fa-thumbs-down" />
                  </a>
                </div>
              )}
              <a
                data-tip="Expandir vídeo em tela inteira"
                role="button"
                className="btn"
                onClick={this.onClickFullscreen}
              >
                <i className="fa fa-expand" aria-hidden="true" />
              </a>
              {flavors.length > 0 && (
                <div className="btn-group dropup">
                  <button
                    type="button"
                    data-tip="Ajuste a velocidade do vídeo"
                    className="btn"
                    style={{
                      color: 'white',
                      background: 'transparent',
                      borderRadius: '.25rem'
                    }}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-cog" />
                  </button>
                  <div
                    className="dropdown-menu video-control"
                    style={{ minWidth: 95 }}
                  >
                    {flavors.map(flavor => {
                      return (
                        <button
                          key={flavor.id}
                          className="dropdown-item"
                          onClick={() => this.handleChangeResolution(flavor.id)}
                          type="button"
                        >
                          {flavor.height} {flavor.id === selectedFlavor && '✓'}
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="btn-group dropup">
                <button
                  type="button"
                  data-tip="Ajuste a velocidade do vídeo"
                  className="btn"
                  style={{
                    color: 'white',
                    background: 'transparent',
                    borderRadius: '.25rem'
                  }}
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {playbackRate}x <i className="fa fa-clock-o" />
                </button>
                <div className="dropdown-menu video-control">
                  {speeds.map(x => {
                    return (
                      <button
                        key={x}
                        className="dropdown-item"
                        onClick={() => this.setState({ playbackRate: x })}
                        type="button"
                      >
                        {x === playbackRate && '✓'} {x === 1 ? 'Normal' : x}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Styles.ObjectDescription>
          <ViewEditor data={this.props.selected.shortDescription || this.props.selected.longDescription} />
        </Styles.ObjectDescription>

        {knowMoreList.length > 0 && <h4>SAIBA MAIS - Arquivos</h4>}

        {knowMoreList.length > 0
          && knowMoreList.map((item, index) => {

            const { media } = item;

            return (
              <Styles.ItemContainer key={index}>
                <Styles.ContainerIcon>
                  {_.get(media, 'type', '?')}
                </Styles.ContainerIcon>
                <Styles.ItemDescription
                  style={
                    this.state.playedSeconds >= media.position &&
                      this.state.playedSeconds < media.position + 10 &&
                      media.position > 0
                      ? {
                        flex: 2,
                        fontSize: 22,
                        transition: 'font-size 2s'
                      }
                      : {
                        flex: 2,
                        fontSize: 14,
                        transition: 'font-size 2s'
                      }
                  }
                >
                  {_.get(media, 'name', '?')}
                </Styles.ItemDescription>
                {_.get(media, 'path', false) && (
                  <Styles.BtnDownload
                    type="button"
                    className="btn btn-light"
                    onClick={() =>
                      this.downloadAnexo({ path: media.path })
                    }
                  >
                    <i className="fa fa-download" aria-hidden="true" />
                    <a target="" />
                  </Styles.BtnDownload>
                )}
              </Styles.ItemContainer>
            );
          }
          )}
        <Modal
          open={shareScreen}
          onClose={this.shareMenu}
          classNames={{ modal: 'lit-perfil-form' }}
          closeOnOverlayClick={false}
        >
          <ModalShare close={this.shareMenu} showDuvidas={showDuvidas} />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selected: state.newCourse.selected,
  course: state.newCourse.data,
  actionPlayVideo: state.newCourse.actionPlayVideo,
  progress: state.newCourse.progress,
  idCourse: state.newCourse.data.idCourse,
  playerPosition: state.newCourse.playerPosition,
  userRequestSeekPlayer: state.newCourse.userRequestSeekPlayer,
  navigationContent: state.newCourse.navigationContent,
  autoplayNextVideos: state.newCourse.autoplayNextVideos,
  muted: state.newCourse.muted,
  warningDisplayed: state.newCourse.warningDisplayed,
  courseSession: state.newCourse.courseSession
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(CourseActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Podcast);
