import React, { memo, useState, useCallback } from "react";

import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Modal from "../../../../LitAtomicComponents/Modals/GenericModal";
import history from '../../../../../utils/history';



const LaneContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  position: relative;
`;

const LaneHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
`;

const LaneTitle = styled.h2`
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
`;

const LaneCount = styled.span`
  font-size: 20px;
  color: #D3D3D3;
  margin-left: 8px;
  margin-bottom: 8px;
`;

const EvaluationCard = styled.div`
  min-width: 300px; 
  max-width: 360px;
  flex-grow: 1; 
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin: 0 15px;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

const EvaluationImage = styled.img`
  width: 100%;
  height: 160px;
`;

const EvaluationTitle = styled.div`
  font-size: 14px;
  color: #333;
  padding: 10px;
  margin-left: 4px;
`;

const ArrowButton = styled.button`
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #333;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => (props.left ? "left: 10px;" : "right: 10px;")}

  &:hover {
    background-color: #e0e0e0;
  }
`;

const CustomLeftArrow = ({ onClick }) => (
  <ArrowButton left onClick={onClick}>
    &#10094;
  </ArrowButton>
);

const CustomRightArrow = ({ onClick }) => (
  <ArrowButton onClick={onClick}>
    &#10095;
  </ArrowButton>
);

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ModalImage = styled.img`
  width: 100%;
  max-width: 500px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
  font-size: 22px;
  color: #333;
  margin: 10px 0;
  text-align: center;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ProgressLabel = styled.div`
  font-size: 16px;
  color: #666;
  margin-top: 10px;
  text-align: left;
  width: 100%;
`;

const ProgressBar = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  height: 8px;
  margin: 10px 0;
`;

const ProgressFill = styled.div`
  height: 100%;
  width: ${({ progress }) => progress}%;
  background-color: #3498db;
`;
const CertificateButton = styled.button`
  background-color: ${({ disabled }) => (disabled ? "#cccccc" : "#3498db")};
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  margin-left: 10px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? "#cccccc" : "#2980b9")};
  }
`;

const FinalGrade = styled.span`
  font-size: 16px;
  color: #666;
`;

const ContinueButton = styled.button`
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2980b9;
  }
`;

const truncateText = (text, maxLength) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};

const responsive = {
  veryDesktop: {
    breakpoint: { max: 3000, min: 1560 },
    items: 5,
    slidesToSlide: 2,
  },
  largDesktop: {
    breakpoint: { max: 1560, min: 1385 },
    items: 4,
    slidesToSlide: 2,
  },
  desktop: {
    breakpoint: { max: 1385, min: 1024 },
    items: 3,
    slidesToSlide: 2, 
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const CourseLanes = ({ coursesData = [] }) => {
  const [courseIndex, setCourseIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleCardClick = (index) => {
    setCourseIndex(index);
    setOpenModal(true);
  };

  const handleRedirectCourse = useCallback((code) => {
    history.push(`/curso-novo/${code}`)
  }, []);

  const handleViewCertificate = useCallback((certificate) => {
    window.open(certificate.url, "_blank");
  }, []);

  const renderModal = () => {
    const course = coursesData[courseIndex];
    if (!course) return null;

    const courseProgress = (100 *  course.countFinishedObjects) / course.countObjects || 0;

    return (
      <Modal
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
        textHeader={truncateText(course.name, 50)}
      >
        {console.log(course, 'course')}
        <ModalContent>
          <ModalImage src={course.media.path} alt={course.name} />
          <ModalTitle>{truncateText(course.name, 50)}</ModalTitle>
          <ProgressLabel>Meu progresso</ProgressLabel>
          <ProgressBar>
            <ProgressFill progress={courseProgress} /> {/* Ajuste o progresso conforme necessário */}
          </ProgressBar>
          <ModalActions>
          
            <FinalGrade>Média final: {course.gradeAverage}</FinalGrade>
            <div>
            <ContinueButton onClick={() => handleRedirectCourse(course.code)}>Continuar</ContinueButton>

            <CertificateButton disabled={!course.certificate} onClick={() => handleViewCertificate(course.certificate)}>
               Ver Certificado
              </CertificateButton>
            </div>

          </ModalActions>
        </ModalContent>
      </Modal>
    );
  };

  return (
    <LaneContainer>
      <LaneHeader>
        <LaneTitle>Disciplinas da turma</LaneTitle>
        <LaneCount>({coursesData.length})</LaneCount>
      </LaneHeader>
      <Carousel
        responsive={responsive}
        swipeable
        draggable
        showDots={false}
        infinite={false}
        keyBoardControl
        customTransition="all 0.5s"
        transitionDuration={500}
        containerClass="carousel-container"
        itemClass="carousel-item-padding-10-px"
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {coursesData.map((course, index) => (
              <>
                <EvaluationCard key={index} onClick={() => handleCardClick(index)}>
                            <EvaluationImage src={course.media.path} alt={course.name} />
                            <EvaluationTitle>{truncateText(course.name, 34)}</EvaluationTitle>
                </EvaluationCard>
                 <p style={{marginBottom: '2px'}}></p>
              </>

        ))}
      </Carousel>
      {openModal && renderModal()}
    </LaneContainer>
  );
};

export default memo(CourseLanes);
